<template lang="pug">
v-dialog.center(v-model='display')
    .myRow
        v-card.addPadding
            v-col
                v-row.padBottom(justify='center')
                    v-card-title Add Tag
                v-row
                    v-text-field(label='Tag' v-model='tag')
                v-row(justify='center')
                    v-btn(:disabled='tag.length === 0' @click='handleClick') Add

</template>

<script>

export default {
  name: 'Overview',
  props: ['playlist'],
  async mounted() {
  },
  data: () => ({
      display: false,
      tag: ''
  }),
  methods: {
      displayModal() {
          this.display = true
          this.tag = ''
      },
      async handleClick() {
          const tagIndex = this.playlist.tags.findIndex(elem => elem === this.tag)

          if (tagIndex !== -1) {
              alert('That tag already exists in this playlist.')
              return
          }

          await this.$dataGrabber.addTagToPlaylist(this.playlist.docId, this.tag)
          this.tag = ''
          this.display = false
      }
  },
  computed: {
  }
}
</script>
<style scoped>
.addPadding {
    width: 600px;
    padding: 100px;
}
.padBottom {
    margin-bottom: 50px;
}
.myRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
