<template lang="pug">
.heroDiv(:style='`min-height:${height};`')
  .myCol
    .playlistHoundRow
      img(src='../assets/images/playlisthoundlogo.png')
    v-timeline
      v-timeline-item(icon='mdi-account-plus')
        v-card.pointer(@click="redirect('sign-in')")
          v-card-title.red1 Sign Up
          v-card-text Sign up with one click using Google.
      v-timeline-item(icon='mdi-pencil')
        v-card.pointer(@click="redirect('playlists')")
            v-card-title.red1 Create
            v-card-text Make a playlist! All you need is a name for it.
      v-timeline-item(icon='mdi-robot-happy')
        v-card.pointer(@click="redirect('playlists')")
            v-card-title.red1 Enjoy
            v-card-text Visualize your playlist and curate it.
      v-timeline-item(icon='mdi-repeat')
        v-card.pointer(@click="redirect('playlists')")
            v-card-title.red1 Repeat
            v-card-text Watch how your taste changes as your playlists change.
</template>

<script>

export default {
  name: 'Home',
  methods: {
    redirect(location) {
      this.$router.push(`/${location}`)
    }
  },
  data: () => ({
    height: '100%'
  }),
  async mounted() {
    const windowHref = window.location.href
    window.postMessage(windowHref, '*')
    const height = window.innerHeight
    const navBarHeight = document.getElementById('navBar')
    if (navBarHeight) {
      const navBarRect = navBarHeight.getBoundingClientRect()
      const height1 = navBarRect.height
      this.height = (height - height1) + 'px'
    }
  }
};
</script>

<style scoped lang='scss'>
.red1 {
  background: #721d29;
  color: white;
}
.heroDiv {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    color: #FF9B54;
    overflow: scroll;
    background: linear-gradient(0.25turn, #721d29, #FF9B54, #721d29);
    background-size: cover;
    overflow-y: hidden;
}
.heroDiv::-webkit-scrollbar {
  visibility: hidden;
}

.heroDiv h1 {
    font-family: inherit;
    font-size: xxx-large;
}

.heroDivText1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

.heroDivText2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 340px;
    height: 300px;
    overflow: hidden;
}
.heroDivText2 img {
    width: max-content;
    height: 300px;
}
.bassetImage {
  position: absolute;
  bottom: -100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.playlistHoundRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
}
.myCol {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
.pointer {
  cursor: pointer;
}
</style>
