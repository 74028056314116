<template lang="pug">
.Overview
  .bottomPercent(:key='bottomKey')
    .leftSide
      .statsDiv
        .flexOne
          .flexOneTitle
            | General information
          v-card.scaleUp
            .topArtist Name: {{ playlist.name }}
            .topArtist Owner: {{ playlist.userName }}
            .topArtist Version: {{ currentVersion.toFixed(1) }}
            .topArtist Number of Songs: {{ numberOfSongs }}
            .topArtist Created:{{ createdAt }}
        .flexOne
          .flexOneTitle
            | Top artists
          v-card.scaleUp
            .topArtist 1. {{ topArtists[0] }}
            .topArtist 2. {{ topArtists[1] }}
            .topArtist 3. {{ topArtists[2] }}
        .flexOne
          .flexOneTitle
            | Top genres
          v-card.scaleUp
            .topArtist 1. {{ topGenres[0] }}
            .topArtist 2. {{ topGenres[1] }}
            .topArtist 3. {{ topGenres[2] }}
    .rightSide
      .statsDiv
        .flexOne
          .flexOneTitle
            | Contributors
          v-card.scaleUp
            .addContributorRow
              v-btn(:disabled='!isOwner' @click='addContributor') Add
            .contributorsScroll
              .contributor(@click='$router.push(`/users/${editor.userId}`)') {{ playlist.userName }} (Owner)
              .contributor.darkerColor(v-for='editor in playlist.editors' :key='editor.userId' @click='$router.push(`/users/${editor.userId}`)') {{ editor.userName }}
        .flexOne
          .flexOneTitle
            | Tags
          v-card.scaleUp
            .addContributorRow
              v-btn(:disabled='!isOwner' @click='addTag') Add
            .tagScroll
              .tag(v-for='tag in playlist.tags' :key='tag') {{ tag }}
    AddTag(ref='addTag' :playlist='playlist')
    AddContributor(ref='addContributor' :playlist='playlist')
</template>

<script>
import { format } from 'date-fns'

import AddContributor from './AddContributor.vue'
import AddTag from './AddTag.vue'

export default {
  name: 'Overview',
  props: ['playlist', 'songs', 'currentVersion', 'artists'],
  components: {
    AddTag,
    AddContributor
  },
  async mounted() {
    this.fetchData()
  },
  data: () => ({
    topArtists: ['N/A', 'N/A', 'N/A'],
    topGenres: ['N/A', 'N/A', 'N/A'],
    bottomKey: 0
  }),
  watch: {
    'songs': function() {
      this.fetchData()
    },
  },
  methods: {
    addTag() {
      this.$refs.addTag.displayModal()
    },
    addContributor() {
      this.$refs.addContributor.displayModal()
    },
    fetchData() {
      if (this.artists) {
        const topArtists = this.artists.slice(0, this.artists.length).sort((a,b) => b.artistCount - a.artistCount).slice(0, 3).map(artist => artist.name + ` (${artist.artistCount})`)
        let iter = 0
        for (const a of topArtists) {
          this.topArtists[iter] = a
          iter++
        }
      }
      const genresDict = []
          for (const artist of this.artists) {
              const genres = artist.genres
              for (const genre of genres) {
                  if (genre in genresDict) {
                      genresDict[genre]++
                  } else {
                      genresDict[genre] = 1
                  }
              }
          }

        const artists = []
        for (const artist in genresDict) {
          const a = {
            name: artist,
            value: genresDict[artist]
          }
          artists.push(a)
        }
        artists.sort((a,b) => b.value - a.value)
        const myArtists = artists.slice(0, 3).map(elem => elem.name + ` (${elem.value})`)

        let iter = 0
        for (const a of myArtists) {
          this.topGenres[iter] = a
          iter++
        }
        this.bottomKey++
    }
  },
  computed: {
    isOwner() {
      return this.$store.getters.getCurrentUser && this.$store.getters.getCurrentUser.uid === this.playlist.userId
    },
    numberOfSongs() {
      return this.songs.length
    },
    numberOfArtists() {
      if (!this.playlist) {
        return 0
      } else {
        if (this.playlist.uniqueArtists) {
          return Object.keys(this.playlist.uniqueArtists).length
        } else {
          return 0
        }
      }
    },
    createdAt() {
      if (!this.playlist) {
        return 'N/A'
      } else {
        return format(new Date(this.playlist.createdAt.seconds * 1000), 'MMMM do, yyyy')
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.title {
  font-size: 40px;
  font-size: bolder;
  color: white;
}
.contributorsDiv {
  height: 46%;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tagsDiv {
  height: 46%;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
}
.addContributorRow {
  display: flex;
  padding: 5px;
  flex-direction: row;
  justify-content: flex-end;
}
.contributorsTitle {
  margin-left: 16px;
  font-weight: bolder;
  letter-spacing: 1.5px;
}
.contributorsScroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
}
.contributorsScroll::-webkit-scrollbar {
  visibility: hidden;
}
.contributor {
  margin-left: 20px;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}
.contributor:hover {
  text-decoration: underline;
}
.tagScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-x: hidden;
  padding: 20px;
}
.tagScroll::-webkit-scrollbar {
  visibility: hidden;
}
.tag {
  width: 30%;
  height: 20px;
  background-color: #ce4257;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  border-bottom: 2px solid black !important;
}
.darkerColor {
  color: rgb(78, 69, 69) !important;
}
.statsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.topArtists {
  font-size: 24px;
  font-weight: bolder;
}
.topArtist {
  font-size: 22px;
  letter-spacing: 1.5px;
}
.padLeft {
  margin-left: 5px;
}
.date {
  font-size: 20px;
  letter-spacing: 1.25px;
}
.topPercent {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
}
.myRow {
  display: flex;
  flex-direction: row;
}
.text {
  font-size: 1.25rem;
}
.overflowScroll {
  overflow: scroll !important;
  overflow-x: hidden !important;
}
.flexOne {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px;
}
.flexOneTitle {
  color: white;
}
.scaleUp {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 16px;
}
// desktop
@media only screen and (min-width: 401px) {
  .Overview {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
  .leftSide {
    display: flex;
    flex-direction: column;
    flex: 6;
    height: 100%;
  }
.rightSide {
  display: flex;
  flex-direction: column;
  flex: 6;
  height: 100%;
}
.bottomPercent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
}
// mobile
@media only screen and (max-width: 400px) {
  .Overview {
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}
  .leftSide {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
.rightSide {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.bottomPercent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
}
</style>
