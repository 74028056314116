<template lang="pug">
.dataTable
    .searchBar
        v-text-field(v-model='search' label='Filter' prepend-inner-icon="mdi-magnify" clearable )
    .scrollArea
        v-data-table.fillScrollArea(:items='filteredSongs' :headers='headers')
            template(v-slot:item.artistString2='props')
                .row
                    .linkOut(@click='openSpotifyArtistLink(artist)' style='margin-right: 5px;' v-for='artist in props.item.artists' :key='`song${props.item.id}${artist.id}`') {{ artist.name }},
            template(v-slot:item.name='props')
                .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
            template(v-slot:item.createdAt='props')
                | {{ formatDate(props.item.createdAt) }}
            template(v-slot:item.userName="props")
                .circlePerson(:title='props.item.userName') {{ initials(props.item.userName) }}
            template(v-slot:item.status="props")
                .header(v-if="props.item.action === 'proposed-addition'")
                    .center
                        v-btn.button(:disabled='!canEdit' @click='handleApproval(props.item)') Resolve
                .header(v-else-if="props.item.action === 'proposed-removal'")
                    .center
                        v-btn.button(:disabled='!canEdit' @click='handleRejection(props.item)') Resolve
                .header(v-else)
                    .center
                        v-btn.button(:disabled='!canEdit' @click='rejectSong(props.item)')
                            v-icon mdi-delete
    v-dialog(v-model='makingDecision' width='400')
        v-card
            v-col
                v-row(justify='center')
                    v-card-title {{ decisionText }}
                v-row(justify='center')
                    v-col(align='center')
                        v-btn(@click='handleYes') Yes
                    v-col(align='center')
                        v-btn(@click='handleNo') No
</template>

<script>
import { format } from 'date-fns'
export default {
  name: 'DataTable',
  props: ['playlist', 'canEdit', 'songs'],
  async mounted() {
  },
  data: () => ({
      search: '',
      makingDecision: false,
      selectedSong: undefined,
      decisionText: "Add song to playlist?",
      type: 'Approval',
      headers: [
          { text: 'Name', value: 'name' },
          { text: 'Artist', value: 'artistString2' },
          { text: 'Added', value: 'createdAt' },
          { text: 'Action By', value: 'userName' },
          { text: 'Status', value: 'status' }
      ]
  }),
  computed: {
      filteredSongs() {
          const s = this.search.toLowerCase()
          if (s === '') {
            return this.songs
          } else {
              return this.songs.filter(song => {
                const songName = song.name.toLowerCase()
                const artistName = song.artistString.toLowerCase()
                return songName.indexOf(s) !== -1 || artistName.indexOf(s) !== -1
              })
          }  
      }
  },
  methods: {
      openSpotifyArtistLink(artist) {
          window.open(`https://open.spotify.com/artist/${artist.id}`)
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      formatDate(date) {
          return format(date, "LL/d/yy hh:mma");
      },
      async handleArtist(artist) {
          this.$emit('artistClick', artist)
      },
      async handleYes() {
          // add
          if (this.type === 'Approval') {
              await this.approveSong(this.selectedSong)
          } else {
              // remove
            await this.removeSong(this.selectedSong)
          }
          this.makingDecision = false
      },
      async handleNo() {
          // rejected addition
          if (this.type === 'Approval') {
              await this.rejectAddition(this.selectedSong)
          } else {
              // rejected removal
            await this.rejectRemoval(this.selectedSong)
          }
          this.makingDecision = false
      },
      handleApproval(song) {
          this.selectedSong = song
          this.makingDecision = true
          this.decisionText = "Add song to playlist?"
          this.type = 'Approval'
      },
      handleRejection(song) {
          this.selectedSong = song
          this.makingDecision = true
          this.decisionText = "Remove song from playlist?"
          this.type = 'Rejection'
      },
      async approveSong(song) {
          const spotifyId = song.id
            const myObj = {
            spotifyId,
            action: 'accepted-addition',
            version: this.playlist.version,
            createdAt: new Date(),
            playlistId: this.playlist.docId
            }
        await this.$dataGrabber.addSongToPlaylist(myObj)
      },
      async rejectSong(song) {
          const spotifyId = song.id
        const myObj = {
          spotifyId,
          action: 'proposed-removal',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId
        }
        await this.$dataGrabber.addSongToPlaylist(myObj)
      },
      async rejectAddition(song) {
          const spotifyId = song.id
        const myObj = {
          spotifyId,
          action: 'rejected-addition',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId
        }
        await this.$dataGrabber.addSongToPlaylist(myObj)
      },
      async rejectRemoval(song) {
          const spotifyId = song.id
        const myObj = {
          spotifyId,
          action: 'rejected-removal',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId
        }
        await this.$dataGrabber.addSongToPlaylist(myObj)
      },
      async removeSong(song) {
          const spotifyId = song.id
        const myObj = {
          spotifyId,
          action: 'accepted-removal',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId
        }
        await this.$dataGrabber.addSongToPlaylist(myObj)
      },
      initials(word) {
          const letters = word.split(" ")
          if (letters.length > 1) {
            return letters[0].substring(0,1).toUpperCase() + letters[1].substring(0,1).toUpperCase()
          } else {
            return letters[0].substring(0,1).toUpperCase()
          }
          
      }
  }
}
</script>
<style scoped lang='scss'>
    .linkOut:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .dataTable {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .buttonRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px;
    }
    .nudgeLeft {
        margin-right: 20px;
    }
    .pageInformation {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
    }
    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .greenBackground {
        background-image: linear-gradient(to right, #569756, #c4f3c4);
        border: none !important;
    }
    .redBackground {
        background-image: linear-gradient(to right, red, #dec0ff);
        border: none !important;
    }
    .headers {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 5%;
    }
    .header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
    .headerTop {
        width: 20%;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .scrollArea {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: scroll;
        overflow-x: hidden;
        height: 85%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .dataRow {
        display: flex;
        flex-direction: row;
        width: auto;
        height: 80px;
        min-height: 80px;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
        margin: 5px;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    .circlePerson {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: red;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 2px;
        border: 2px solid white;
        cursor: pointer;
    }
    .centerHeader {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .button {
        width: 100px !important;
    }
    .artistHover:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .fillScrollArea {
        width: 100%;
        height: 100%;
    }
    .row {
        display: flex;
        flex-direction: row;
    }
    // desktop
@media only screen and (min-width: 401px) {
        .searchBar {
        height: 10%;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}
// mobile
@media only screen and (max-width: 400px) {
        .searchBar {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
