import { render, staticRenderFns } from "./Playlist.vue?vue&type=template&id=4bb24c05&scoped=true&lang=pug"
import script from "./Playlist.vue?vue&type=script&lang=js"
export * from "./Playlist.vue?vue&type=script&lang=js"
import style0 from "./Playlist.vue?vue&type=style&index=0&id=4bb24c05&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb24c05",
  null
  
)

export default component.exports