<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  name: 'LineChart',
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    'chartData': function() {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

<style>
</style>
