import '@mdi/font/css/materialdesignicons.css'

import { initializeApp } from "@firebase/app";
import { getAuth } from "firebase/auth";
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import * as firebaseService from './services/firebaseService'
import * as spotifyService from './services/spotifyService'
import store from './store'

const config = {
  apiKey: "AIzaSyD-2JrtseGn_98yYlxiDCqHaaZPYnRLhAM",
  authDomain: "playlisthound.firebaseapp.com",
  projectId: "playlisthound",
  storageBucket: "playlisthound.appspot.com",
  messagingSenderId: "297648327246",
  appId: "1:297648327246:web:60ac8b3e6d8a3ad268511f",
  measurementId: "G-HRCQ1P6M2E",
  databaseURL: "https://playlisthound-default-rtdb.firebaseio.com/"
}

initializeApp(config)

getAuth().onAuthStateChanged((authImpl) => {
  if (authImpl) {
    const { auth } = authImpl
    if (auth) {
      const { currentUser } = auth
      store.commit('setCurrentUser', currentUser)
      firebaseService.updateUser()
    }
  }
})

Vue.config.productionTip = false
Vue.prototype.$dataGrabber = firebaseService
Vue.prototype.$spotifyService = spotifyService

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
