<template lang="pug">
  .myCol
    .searchAreaRow
      SearchArea(:canSearch='true' :searchHeight='searchHeight' @selectSong='handleSongAddition' @artistSelect='handleArtistClick')
      ArtistView(ref='artistView' @songSelect='handleSongAddition' :fullscreen='true')
    v-snackbar(v-model='visible' color='transparent' timeout='1500')
      v-alert(type='success') Thank you for your suggestion!
</template>

<script>
import { getRecommendations } from '../services/spotifyService'
import ArtistView from './ArtistView.vue'
import SearchArea from './SearchArea.vue'

export default {
  name: 'QueueSearch',
  components: {
    SearchArea,
    ArtistView
  },
  data: () => ({
    height: '100%',
    tab: 0,
    tabs: ['Search', 'Recommendations'],
    visible: false,
    recs: [],
    searchHeight: '500px'
  }),
  watch: {
    'addedSongIds': function() {
     getRecommendations({ tracks: this.addedSongIds, target: 100, popularity: 95}).then(res => {
        this.recs = res.slice(0, 10)
      })
    }
  },
  async mounted() {
    this.searchHeight = window.innerHeight - 160 - 32 + 'px'
  },
  methods: {
    personalAlert() {
      this.visible = true
    },
    async handleArtistClick(artist) {
      let val = this.$store.state.artistsCache[artist.id]
      if (!val) {
        val = await this.$spotifyService.getArtist(artist.id)
      }
      this.$refs.artistView.displayModal(val)
    },
    async handleSongAddition(song) {
        // assemble song object
        this.$emit('songSelected', song)
        this.personalAlert()
    }
  }
};
</script>

<style scoped lang='scss'>
.myCol {
  min-height: 100%;
  width: 100%;
  background-color: #721d29;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}
.playlistNameRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #ff9b54;
    font-size: 30px;
}
.playlistOwnerRow {
    color: rgba(255,155,84,.5882352941176471);
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
}
.tagsDiv {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.tagsTitle {
  font-weight: bolder;
  color: white;
  font-size: 20px;
}
.tagScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}
.tag {
  width: 46%;
  height: 20px;
  background-color: #ce4257;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 1%;
  border-bottom: 2px solid black !important;
}
.tagScroll::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.suggestASongRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 18px;
}
.searchAreaRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}
.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
}
.moreInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}
.tabsRow {
  margin-bottom: 20px;
}
.tabOne {
  display: flex;
  flex-direction: column;
}
.noRecsText {
  text-align: center;
  color: white;
  font-size: 20px;
}
.recs {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
}
.recsBox {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  background: #f1f2f2;
}
.rec {
    letter-spacing: 1.1px;
    cursor: pointer;
    font-size: 16px;
    width: 98%;
    margin: 5px;
    height: 80px;
    min-height: 80px;
    background-color: white;
    display: flex;
    flex-direction: row;
}
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 100%;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.songInfoName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
.songArtistName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: gray;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    height: 100%;
    width: 20%;
}
</style>