<template lang='pug'>
    .background
        .scanMeText
            | Have a freind scan this to join
        .imageContainer
            v-menu
                template(v-slot:activator="{ on, attrs }")
                    img.myImage(:src='myImage' v-bind="attrs" v-on="on" close-on-content-click)
                v-list
                    v-list-item(Style='align-items:center;' @click='sendMessage')
                        v-icon mdi-export-variant
                        | Share SMS
        .scanMeText
            | Click on QRCode to share
</template>

<script>
import QRCode from 'qrcode'

    export default ({
        name: 'QueueQRCode',
        data: () => ({
            myImage: ''
        }),
        async mounted() {
            QRCode.toDataURL(window.location.href).then(url => {
                this.myImage = url
            })
        },
        methods: {
            sendMessage() {
                window.open(`sms://&body=${encodeURI('Click to join music group session on playlisthound!' + '\n' + '\n' + window.location.href + '\n')}`)
            }
        }
    })
</script>

<style lang='scss' scoped>
    .background {
        min-height: 100%;
        width: 100%;
        background-color: #f1f2f2;
        padding: 16px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll !important;
        padding-bottom: 100px;
    }
    .imageContainer {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .myImage {
        width: 250px;
        height: 250x;
    }
    .scanMeText {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-size: 30px;
        margin-top: 20px;
    }
</style>