<template lang="pug">
    .ClusterTable
        .topRow
            .kMenu
                v-menu(offset-y)
                    template(v-slot:activator="{ on, attrs }")
                        v-btn(v-on='on' v-bind="attrs") K {{ chosenKNum }}
                    v-list.truncateList
                        v-list-item(v-for='obj in sse' :key='obj.k' @click=' chosenKNum = obj.k ')
                            v-list-item-title K {{ obj.k }}
                            v-icon(v-if='obj.k === chosenKNum') mdi-star
            .clusterMenu
                v-menu(offset-y)
                    template(v-slot:activator="{ on, attrs }")
                        v-btn(v-on='on' v-bind="attrs") Cluster {{ clusterNum }}
                    v-list.truncateList
                        v-list-item(v-for='obj, num in sse[chosenKNum - 1].clusters' :key='`cluster${num}`' @click=' clusterNum = num ')
                            v-list-item-title Cluster {{ num }}
        .bottomRow
            v-data-table.maxWidth(:items='sse[chosenKNum - 1].clusters[clusterNum]' :headers='headers' :loading='loading')
                template(v-slot:item.artistString2='props')
                    .row
                        .linkOut(@click='openSpotifyArtistLink(artist)' style='margin-right: 5px;' v-for='artist in props.item.artists' :key='`song${props.item.id}${artist.id}`') {{ artist.name }},
                template(v-slot:item.name='props')
                    .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
</template>

<script>
import pld from 'point-line-distance'
import skmeans from 'skmeans'

export default ({
    name: 'ClusterTable',
    props: ['songs'],
    async mounted() {
        if (this.songs && this.songs.length > 0) {
                this.createClustersWrap()
        }
    },
    data: () => ({
        loading: true,
        chosenKNum: 1,
        clusterNum: '0',
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Artist', value: 'artistString2' }
        ],
        sse: [{ clusters: [] }]
    }),
    computed: {
        currentCluster() {
            if (!this.clusters) {
                return []
            } else {
                return this.clusters[this.clusterNum]
            }
        }
    },
    watch: {
        'songs': function(songs) {
            if (songs && songs.length > 0) {
                this.createClustersWrap()
            }
        }
    },
    methods: {
    openSpotifyArtistLink(artist) {
          window.open(`https://open.spotify.com/artist/${artist.id}`)
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      async createClustersWrap() {
          const out = []
          const max = this.songs ? this.songs.length < 20 ? this.songs.length : 20 : 0
          for (let i = 1; i <= max; i++) {
            const clusters = await this.createClusters(i)
            out.push(clusters)
          }
          const lineStart = [1, out[0].sse, 0]
          const lineEnd = [max, out[max - 1].sse, 0]
          const distances = []
          for (const iter of out) {
              const point = [iter.k, iter.sse, 0]
              distances.push({object : iter, distance: pld(point, lineStart, lineEnd)})
          }
          distances.sort((b,a) => a.distance - b.distance)
          const chosen = distances[0].object.clusters
          this.chosenKNum = distances[0].object.k
          this.$emit('clusters', chosen)
          this.sse = out
          this.loading = false
      },
      async createClusters(k) {
        const songs = this.songs
        const data = songs.map(song => {
            const { analysis, duration_ms } = song
            const { danceability, energy, key, loudness, mode, speechiness, acousticness, instrumentalness, liveness, valence, tempo, time_signature } = analysis
            return [duration_ms, danceability, energy, key, loudness, mode, speechiness, acousticness, instrumentalness, liveness, valence, tempo, time_signature]
        })
        const calculate_dist = (point1, point2) => {
            let sum1 = 0
            for (let j = 0; j < point1.length; j++) {
                sum1 += Math.pow(point1[j] - point2[j], 2)
            }
            return sum1
        }
        const clusters = skmeans(data, k)
        let sum = 0
        const { idxs, centroids } = clusters
        const out = {}
        const out1 = {}
        for (let i = 0; i < k; i++) {
            out[i] = []
            out1[i] = []
        }
        let index = 0
        for (const val of idxs) {
            out[val].push(songs[index])
            out1[val].push(data[index])
            index++
        }
        for (index = 0; index < k; index++) {
            const current_centroid = centroids[index]
            for (const point of out1[index]) {
                sum += calculate_dist(current_centroid, point)
            }
        }
        return { clusters: out, sse: Math.round(sum), k }
      },
    }
})
</script>

<style scoped lang='scss'>
    .ClusterTable {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: scroll;
    }
    .topRow {
        display: flex;
        height: 80px;
        width: 100%;
    }
    .bottomRow {
        display: flex;
        flex: 1;
        width: 100%;
    }
    .maxWidth {
        width: 100%;
    }
    .clusterMenu {
        padding-left: 20px;
    }
    .truncateList {
        display: flex;
        flex-direction: column;
        height: 400px;
        overflow-y: scroll;
    }
    .row {
        display: flex;
        flex-direction: row;
    }
    .linkOut:hover {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
