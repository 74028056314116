<template lang="pug">
v-dialog(v-model='show')
    v-card.pad(width='800' height='400')
        v-col(v-if='step === 0')
            .options
                v-card.option(@click='choose("shuffle")')
                    v-icon mdi-shuffle
                    | Shuffle
                v-card.option(@click='choose("linear")')
                    v-icon mdi-chart-line-variant
                    | Linear
            .options
                v-card.option(@click='choose("parabola")')
                    v-icon mdi-chart-bell-curve
                    | Curve
                v-card.option(@click='choose("linear")')
                    v-icon mdi-format-list-numbered
                    | In Order
        .myCol(v-if='step === 1')
            .fullSongsRow
                v-switch(label='Play full songs?' v-model='fullSongs')
            .songSecondsRow(v-if='!fullSongs')
                v-text-field(v-model='songSeconds' label='How many seconds should each song play?' hide-details)
            .submit
                v-btn(@click='handleChoice' :disabled='!validOptions' :loading='exporting') Submit
</template>

<script>

export default {
  name: 'SearchArea',
  props: ['canSearch', 'songs', 'playlist'],
  async mounted() {
  },
  data: () => ({
      show: false,
      text: 'Exporting to PowerHour, Please Wait...',
      step: 0,
      fullSongs: true,
      songSeconds: '',
      choice: '',
      exporting: false
  }),
  computed: {
      validOptions() {
          return this.fullSongs || this.isNumber(this.songSeconds)
      }
  },
  methods: {
      choose(option) {
          this.choice = option
          this.step++
      },
      isNumber(str) {
          if (!str || typeof str !== 'string' || str === '') {
              return false
          }
          const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
          for (const char of str) {
              const index = numbers.findIndex(num => num === char)
              if (index === -1) {
                  return false
              }
          }
          return true
      },
      async handleChoice() {
          switch (this.choice) {
              case 'shuffle': {
                  await this.handleShuffle()
                  break
              }
              case 'normal': {
                  await this.handleNormal()
                  break
              }
              case 'linear': {
                  await this.exportLinear()
                  break
              }
              case 'parabola': {
                  await this.exportParabola()
                  break
              }
          }
      },
      shuffle(arrayParam) {
        const array = arrayParam.slice()
        let currentIndex = array.length,  randomIndex;
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      },
      async handleNormal() {
        this.exporting = true
        const orSixty = this.songs.length < 60 ? this.songs.length : 60
        const sortedSongs = this.songs.slice(0, orSixty).sort((a,b) => b.createdAt - a.createdAt)
        await this.exportToPowerHour(sortedSongs)
      },
      async handleShuffle() {
          this.exporting = true
          const orSixty = this.songs.length < 60 ? this.songs.length : 60
          const shuffledSongs = this.shuffle(this.songs.slice(0, this.songs.length)).slice(0, orSixty)
          await this.exportToPowerHour(shuffledSongs)
      },
      async exportLinear() {
        this.exporting = true
        const orSixty = this.songs.length < 60 ? this.songs.length : 60
        const shuffledSongs = this.shuffle(this.songs.slice(0, this.songs.length)).slice(0, orSixty)
        const sortedSongs = shuffledSongs.sort((a,b) => a.popularity - b.popularity)
        await this.exportToPowerHour(sortedSongs)
      },
      async exportParabola() {
        this.exporting = true
        const orSixty = this.songs.length < 60 ? this.songs.length : 60
        const shuffledSongs = this.shuffle(this.songs.slice(0, this.songs.length)).slice(0, orSixty)
        const songsCopy = shuffledSongs.sort((b,a) => a.popularity - b.popularity)
        let out = []
        for (let i = 0; i < songsCopy.length; i++) {
            if (i === 0) {
                out.push(songsCopy[0])
            } else if (i % 2 === 1) {
                out.push(songsCopy[i])
            } else {
                out = [songsCopy[i], ...out]
            }
        }
        await this.exportToPowerHour(out)
      },
      display() {
          this.text = 'Exporting to PowerHour, Please Wait...'
          this.show = true
          this.step = 0
      },
      async exportToPowerHour(songs) {
        const songsFixed = songs.slice(0, songs.length)
        for (const song of songsFixed) {
            const youtubeData = await this.$dataGrabber.getYoutubeData(song.spotifyId)
            const { youtubeId, songDuration, videoDuration } = youtubeData
            const diff = (videoDuration * 1000) - songDuration
            const startTime = diff > 0 ? Math.round(diff / 1000) : 0
            song.videoDuration = videoDuration
            song.youtubeId = youtubeId
            if (!song.startTime) {
                song.startTime = startTime
            }
        }
        await this.$dataGrabber.createPlayer(this.playlist.docId, songsFixed)
        this.close()

        let url = `/player/${this.playlist.docId}`

        if (this.fullSongs) {
            url += '?songSeconds=fullSongs'
        } else {
            url += `?songSeconds=${this.songSeconds}`
        }

        this.$router.push(url)
      },
      close() {
          this.text = 'Export Complete.'
          const doc = this
          setTimeout(() => {
              doc.show = false
          }, 1000)
      }
  }
}
</script>
<style scoped>
.pad {
    padding: 100px;
    margin: auto;
}
.imageRow {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.imageRow img {
    width: 100%;
}
.textDiv {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;
}
.padTop {
    padding-top: 40px;
}
.padButton {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.option {
    display: flex;
    width: 150px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.submit {
    margin-top: 50px;
}
</style>
