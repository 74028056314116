<template lang="pug">
.playlistsMain(:style="`height: ${height};max-height: ${height}; overflow: hidden;`")
    .addButtonRow
        v-btn(@click='addNewPlaylist') Add Playlist
    v-card.mainCard
        .left
            v-card.card.col(color='#CE4257')
                .filterPlaylistsRow
                    v-text-field(v-model='search' dark label='Search playlists' prepend-inner-icon="mdi-magnify" clearable hide-details)
                .sortPlaylistsCol
                    .sortLabel
                        | Sort by:
                    v-menu(offset-y)
                        template(v-slot:activator="{ on, attrs }")
                            v-btn(v-bind="attrs" v-on="on") {{ sortingMethod }}
                        v-list
                            v-list-item(v-for='option in sortingOptions' :key='option' @click='sortingMethod = option' style="cursor:pointer;")
                                v-list-item-title {{ option }}
        .right
            v-card.card(color='#CE4257')
                .scrollArea(justify='center')
                    v-card.playlist(v-for='playlist in filter(playlists)' :key='playlist.docId')
                        .newCol
                            v-card-title {{ playlist.name }}
                            v-card-subtitle.personName(@click='$router.push(`/users/${playlist.userId}`)') {{ playlist.userName }}
                            .tagsDiv
                                .tagsTitle Tags:
                                .tagScroll
                                    .tag(v-for='tag in playlist.tags' :key='tag') {{ tag }}
                            .buttonRow
                                .icons
                                    .icon
                                        v-icon(title='Views') mdi-eye
                                        | {{ playlist.metrics.views }}
                                    .icon
                                        v-icon(title='Collaborators') mdi-account-group
                                        | {{ getNumCollab(playlist) }}
                                    .icon
                                        v-icon(title='Likes') mdi-heart
                                        | {{ playlist.metrics.likes }}
                                .button
                                    v-btn(@click='$router.push(`/playlist/${playlist.docId}`)') View
                AddPlaylist(ref='addPlaylist')
</template>

<script>
import AddPlaylist from '../components/AddPlaylist.vue'

export default {
  name: 'Playlists',
  components: {
    AddPlaylist
  },
  data: () => ({
    height: '100%',
    playlists: [],
    displayText: 'Loading',
    search: '',
    loading: true,
    interval: null,
    sortingOptions: ['Name', 'Date Created', 'Views', 'Likes'],
    sortingMethod: 'Date Created'
  }),
  watch: {
      'sortingMethod': function() {
          this.sortPlaylists()
      }
  },
  computed: {
      loggedIn() {
          return this.$store.getters.getCurrentUser
      }
  },
  methods: {
      sortPlaylists() {
          function compare( a, b ) {
            if ( a.name.toLowerCase() < b.name.toLowerCase() ){
                return -1;
            }
            if ( a.name.toLowerCase() > b.name.toLowerCase() ){
                return 1;
            }
            return 0;
        }
          switch (this.sortingMethod) {
              case 'Name': {
                  this.playlists.sort(compare)
                  break
              }
              case 'Date Created': {
                  this.playlists.sort((b,a) => (b.createdAt.toDate() - a.createdAt.toDate()))
                  break
              }
              case 'Views': {
                  this.playlists.sort((b,a) => (a.metrics.views - b.metrics.views))
                  break
              }
              case 'Likes': {
                  this.playlists.sort((b,a) => (a.metrics.likes - b.metrics.likes))
                  break
              }
          }
      },
      getNumCollab(playlist) {
          const numEditors = playlist.editors.length
          return numEditors + 1
      },
      addNewPlaylist() {
          this.$refs.addPlaylist.displayModal()
      },
      async getPlaylists() {
          this.playlists = await this.$dataGrabber.getPlaylists()
      },
      filter(arr) {
          if (!this.search || this.search.length === 0) {
              return arr
          }
          const s = this.search.toLowerCase()
          return arr.filter(elem => {
              const name = elem.name.toLowerCase()
              const userName = elem.userName.toLowerCase()
              const myTags = elem.tags.filter(tag => tag.toLowerCase().indexOf(s) !== -1)
              return name.indexOf(s) !== -1 || userName.indexOf(s) !== -1 || myTags.length > 0
            })
      },
      changeLoadingText() {
          const isLoadingText = this.displayText.indexOf('Loading') !== -1
          if (isLoadingText) {
              let numDots = 0
              for (const character of this.displayText) {
                  if (character === '.') {
                      numDots++
                  }
              }
              if (numDots === 3) {
                  this.displayText = 'Loading'
              } else {
                  this.displayText += '.'
              }
          }
      }
  },
  async mounted() {
    const height = window.innerHeight
    const navBarHeight = document.getElementById('navBar')
    this.interval = setInterval(this.changeLoadingText, 150);
    if (navBarHeight) {
      const navBarRect = navBarHeight.getBoundingClientRect()
      const height1 = navBarRect.height
      this.height = (height - height1) + 'px'
    }
    await this.getPlaylists()
    this.displayText = 'Check out the current selection below!'
    this.loading = false
    clearInterval(this.interval)
    this.sortPlaylists()
  },
  beforeDestroy() {
      clearInterval(this.interval)
  }
};
</script>

<style scoped lang='scss'>
    .playlistsMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #721d29;
    }
    .fillPage {
        height: 100% !important;
    }
    .centerText {
        text-align: center;
        font-family: BioRhyme,serif;
        color: #ff9b54;
        font-size: 40px;
        font-weight: bolder;
    }
    .playlistSubtitle {
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
        text-align: center;
        font-size: 20px;
        color: #ff9b54;
        opacity: 1.0 !important;
    }
    .playlistSubtitle:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .playlist {
        width: 300px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 4px;
        margin: 1%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: white;
        color: black;
    }
    .searchRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .whiteInput {
        background-color: white;
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 5px;
        padding-left: 20px;
        letter-spacing: 1.5px;
    }
    .floatingButtonRow {
        position: sticky;
        bottom: 10px;
        left: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: 100px;
    }
    .adjustFloat {
        margin-right: 5px;
    }
    .addButton {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 1px solid;
        background-color: #ce4257;
        text-align: center;
        font-size: xxx-large;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ff9b54;
    }
    .playlistSubtitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .tagsDiv {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .tagsTitle {
        height: 10%;
        font-weight: bolder;
        color: white;
        font-size: 20px;
    }
    .tagScroll {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        flex: 1;
        overflow: scroll;
        overflow-x: hidden;
        height: 90%;
    }
    .tag {
        width: 46%;
        height: 20px;
        background-color: #721d29;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 5px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 5px;
        padding: 1%;
        border-bottom: 2px solid black !important;
    }
    .tagScroll::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .buttonRow {
        display: flex;
        flex-direction: row;
        height: min-content;
        align-items: center;
    }
    .right {
        display: flex;
        flex: 1;
        overflow: hidden;
    }
    .scrollArea {
        overflow-y: scroll !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .newCol {
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 100%;
        height: 100%;
    }
    .icons {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
    }
    .icon {
        padding: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .addButtonRow {
        width: 100%;
        height: 60px;
        min-height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 16px;
    }
    .personName {
        cursor: pointer;
    }
    .personName:hover {
        text-decoration: underline;
    }
    .sortPlaylistsCol {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .sortLabel {
        color: white;
    }
    @media (min-width: 350px) and (max-width: 699px) {
        .mainCard {
            width: calc(100% - 32px);
            height: calc(100% - 32px - 60px);
            margin: 16px;
            display: flex;
            flex-direction: column;
        }
        .left {
            width: 100%;
            height: min-content;
        }
        .card {
            width: calc(100% - 32px);
            height: calc(100% - 32px);
            margin: 16px;
            margin-bottom: 0px !important;
        }
    }
    @media (min-width: 699px) {
        .mainCard {
            width: calc(100% - 32px);
            height: calc(100% - 32px - 60px);
            margin: 16px;
            display: flex;
            flex-direction: row;
        }
        .left {
            width: 300px;
            height: 100%;
        }
        .card {
            width: calc(100% - 32px);
            height: calc(100% - 32px);
            margin: 16px;
        }
    }
</style>
