<template lang="pug">
v-card.searchArea(:style="`height:${searchHeightWrap};`")
    TopSongsByYear(ref='topSongs' @selectSong='selectSong')
    .max
        .smallMargin
            v-text-field#SearchAreaInput(:disabled='!canSearch' v-model='search' label='Search songs/artists' prepend-inner-icon="mdi-magnify" @input='throttle')
                template(v-slot:append)
                    v-icon.clear(@click='clearSearch') mdi-close
        .myNewCol(v-if='searchResults.length > 0')
            .searchResult(v-for='song in searchResults')
                .type(v-if='song.type === "track"' :title='song.name + " by " + song.artists[0].name')
                    .searchIcon
                        v-icon mdi-music
                    .songInfo
                        .songInfoName(@click='openSpotifySongLink(song)')
                            | {{ song.name }}
                        .songArtistName(@click='openSpotifyArtistLink(song)')
                            |  {{ song.artists[0].name }}
                    .serachResultButton
                        v-btn(@click='selectSong(song)')
                            v-icon mdi-plus
                .type(v-else :title='song.name')
                    .searchIcon
                        v-icon mdi-account
                    .songInfo
                        | {{ song.name }}
                    .serachResultButton
                        v-btn(@click='selectArtist(song)')
                            v-icon mdi-account
        .myNewCol(v-else)
            .newButtonArea
                v-btn(@click='browseTopSongs') Browse top songs
</template>

<script>
import TopSongsByYear from './TopSongsByYear.vue'

export default {
  name: 'SearchArea',
  props: ['canSearch', 'searchHeight'],
  components: { TopSongsByYear },
  async mounted() {

  },
  data: () => ({
      search: '',
      interval: null,
      searchResults: []
  }),
  computed: {
      searchHeightWrap() {
          if (!this.searchHeight) {
              return 'auto'
          } else {
              return this.searchHeight
          }
      }
  },
  methods: {
      browseTopSongs() {
          this.$refs.topSongs.openDialog()
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      openSpotifyArtistLink(song) {
          window.open(`https://open.spotify.com/artist/${song.artists[0].id}`)
      },
      selectArtist(artist) {
          this.$emit('artistSelect', artist)
      },
      clearSearch() {
          this.search = ""
          this.searchResults = []
      },
      async searchSpotify() {
          if (this.search.length === 0) {
              this.searchResults = []
              clearInterval(this.interval)
              return
          }
          const results = await this.$spotifyService.search(this.search, 8)
          if (results) {
              const { tracks, artists } = results
              if (tracks) {
                  const trackItems = tracks.items
                  const artistItems = artists.items
                  this.searchResults = artistItems.slice(0, 2).concat(trackItems.slice(0, 8))
              }
          }
          clearInterval(this.interval)
      },
      throttle() {
          clearInterval(this.interval)
          this.interval = setInterval(() => {
              this.searchSpotify()
          }, 500);
      },
      selectSong(song) {
          this.$emit('selectSong', song)
      }
  }
}
</script>
<style scoped>
.searchArea {
    height: calc(90% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
}
.smallMargin {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 0px !important;
    display: flex;
}
@media (min-width: 350px) and (max-width: 699px) {
    .searchIcon {
     width: 0px;
     height: 0px;
     overflow: hidden;
    }
    .searchResult {
        letter-spacing: 1.1px;
        font-size: 16px;
        width: 98%;
        margin: 5px;
        height: 80px;
        min-height: 80px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 100%;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}
}
@media (min-width: 700px) {
    .searchIcon {
     width: 20%;
     align-items: center;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    }
.searchResult {
    letter-spacing: 1.1px;
    font-size: 16px;
    width: 98%;
    margin: 5px;
    height: 60px;
    min-height: 60px;
    background-color: white;
    display: flex;
    flex-direction: row;
}
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 60%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    height: 100%;
    width: 20%;
}
}
.clear {
    cursor: pointer;
}
.myNewCol {
    overflow: scroll;
    background-color: #f1f2f2;
    display: flex;
    flex: 1;
    flex-direction: column;
}
.myNewCol::-webkit-scrollbar {
    width: 0px;
}
.max {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.type {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.songInfoName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
.songInfoName:hover {
    text-decoration: underline;
    cursor: pointer;
}
.songArtistName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: gray;
}
.songArtistName:hover {
    text-decoration: underline;
    cursor: pointer;
}
.newButtonArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
