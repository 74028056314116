import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [],
  modules: {
  },
  state: {
    // spotify token
    spotifyToken: undefined,
    // currently logged-in iser
    currentUser: undefined,
    // current playlist
    currentPlaylist: undefined,
    // spotify songs cache
    songsCache: {},
    // spotify artists cache
    artistsCache: {},
    // subscriptions
    playlistSub: undefined,
    playlistSongsSub: undefined,
    // playlist Interval,
    playlistInterval: null,
    // songs Interval
    songsInterval: null,
    // current queue
    currentQueue: undefined,
    queueSub: null,
    playedSub: null,
    upcomingSub: null,
    // suggestions page,
    currentPlaylistDoc: undefined,
    playlistDocSub: null
  },
  getters: {
    getSpotifyToken: (state) => state.spotifyToken,
    getCurrentUser: (state) => state.currentUser,
    getCurrentPlaylist: (state) => state.currentPlaylist,
    getCurrentQueue: (state) => state.currentQueue,
    getCurrentPlaylistDoc: (state) => state.currentPlaylistDoc
  },
  mutations: {
    setCurrentPlaylistDoc(state, playlistDoc) {
      state.currentPlaylistDoc = playlistDoc
    },
    setPlaylistDocSub(state, sub) {
      if (state.playlistDocSub) {
        const unsubscribe = state.playlistDocSub.value
        unsubscribe()
      }
      state.playlistDocSub = sub
    },
    setQueueSub(state, sub) {
      if (state.queueSub) {
        const unsubscribe = state.queueSub.value
        unsubscribe()
      }
      state.queueSub = sub
    },
    setPlayedSub(state, sub) {
      if (state.playedSub) {
        const unsubscribe = state.playedSub.value
        unsubscribe()
      }
      state.playedSub = sub
    },
    setUpcomingSub(state, sub) {
      if (state.upcomingSub) {
        const unsubscribe = state.upcomingSub.value
        unsubscribe()
      }
      state.upcomingSub = sub
    },
    setPlaylistInterval(state, interval) {
      if (state.playlistInterval) {
        clearInterval(state.playlistInterval)
        state.playlistInterval = interval
      } 
    },
    setSongsInterval(state, interval) {
      if (state.songsInterval) {
        clearInterval(state.songsInterval)
        state.songsInterval = interval
      } 
    },
    setSpotifyToken(state, token) {
      state.spotifyToken = token
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    addPlaylistSubscription(state, sub) {
      if (state.playlistSub) {
        const unsubscribe = state.playlistSub.value
        unsubscribe()
      }
      state.playlistSub = sub
    },
    addPlaylistSongsSubscription(state, sub) {
      if (state.playlistSongsSub) {
        const unsubscribe = state.playlistSongsSub.value
        unsubscribe()
      }
      state.playlistSongsSub = sub
    },
    setCurrentPlaylist(state, playlist) {
      state.currentPlaylist = playlist
    },
    addToSongsCache(state, songs) {
      for (const key in songs) {
        state.songsCache[key] = songs[key]
      }
    },
    addToArtistsCache(state, artists) {
      const out = {}
      for (const artist of artists) {
        out[artist.id] = artist
      }
      for (const key in out) {
        if (!(key in state.artistsCache)) {
          state.artistsCache[key] = out[key]
        }
      }
    }
  }
})

export default store
