<template lang="pug">
    .main
        .left
            .songInfoRow
                .skipSongRow
                    .skipText(@click='skipSong')
                        | Skip
                    .skipText(@click='extendSong')
                        | Play full song
                    .skipText(@click='flagStartTime')
                        v-icon(color='white') mdi-flag
                .songNumInfo
                    | Song {{ songNum }}
            .restOfInfo
                .titleAndCard
                    .title
                        | Time remaining:
                    .card1
                        | {{currentMinute}}:{{ currentSeconds === 0 ? '00': currentSeconds < 10 ? `0${currentSeconds}`: currentSeconds }}
                .titleAndCard
                    .title
                        | Currently playing:
                    .card2
                        .topInfo
                            #songImage(:style='`background-image: url("${currentSong.album.images[0].url}");`')
                            .songYear
                                | {{ currentSong.releaseYear }}
                        .botInfo
                            .songTitle
                                | {{ currentSong.name }}
                            .artistName
                                | {{ currentSong.artists[0].name }}
                .titleAndCard
                    .title
                        | Open on Spotify
                        v-icon(color='green' style='margin-right:2px;' :size='30') mdi-spotify
                        | :
                    .card3
                        #qrCode(:style='`background-image: url("${currentSongUrl}");`')
        .right
            #youtubeVideo
                | Video here
</template>

<script>
import QRCode from 'qrcode'
import YouTubePlayer from 'youtube-player';

import { flagYoutubeStartTime } from '../services/firebaseService.js'

export default ({
    name: 'Player',
    data: () => ({
        player: null,
        data: null,
        currentMinute: 0,
        currentSeconds: 59,
        playFullSong: false,
        songNum: 0,
        currentSongUrl: null,
        currentSong: {
            name: 'Si a veo a tu mama',
            artists: [{ name: 'Bad Bunny' }],
            releaseYear: 2020,
            album: { images: [{ url: '' }] }
        },
        nextSong: null,
        countdownInterval: null,
        songSeconds: 60
    }),
    async mounted() {
        const url = window.location.href
        const index = url.indexOf('?songSeconds=')
        if (index !== -1) {
            const value = url.substring(index + '?songSeconds='.length)
            if (value === 'fullSongs') {
                this.playFullSong = true
            } else {
                const songSeconds = parseInt(value)
                this.songSeconds = songSeconds
            }
        }
        const songNum = this.$route.params.songNum
        if (songNum) {
            this.songNum = songNum > 0 ? songNum - 1 : 0
        }
        const data = await this.$dataGrabber.getPlayer(this.$route.params.playlistId)
        this.data = data
        this.player = await YouTubePlayer('youtubeVideo', {
            height: '100%',
            width: '100%'
        });
        this.player.addEventListener('onStateChange', (event) => {
            const { target } = event
            const playerState = target.getPlayerState()
            if (playerState === 1) {
                if (!this.countdownInterval) {
                    this.resumeCountdown()
                }
            } else if (playerState === 2) {
                this.pauseCountdown()
            }
        })
        await this.loadSong()
    },
    methods: {
        extendSong() {
            const { currentMinute, currentSeconds, songSeconds, currentSong } = this
            
            // how many we started with minus how many we have left
            const secondsPlayed = songSeconds - (currentMinute * 60 + currentSeconds)
            
            const startTime = currentSong.startTime
            const videoDuration = currentSong.videoDuration
            const songDuration = Math.round(currentSong.duration_ms / 1000)
            const value = songDuration > videoDuration ? videoDuration : songDuration
            const almostDiff = value - startTime - 1 - secondsPlayed
            this.currentMinute = Math.floor(almostDiff / 60)
            this.currentSeconds =  almostDiff % 60
        },
        async flagStartTime() {
            flagYoutubeStartTime(this.currentSong.spotifyId)
        },
        skipSong() {
            this.loadSong()
        },
        resumeCountdown() {
            this.startCountdown()
        },
        pauseCountdown() {
            clearInterval(this.countdownInterval)
            this.countdownInterval = null
        },
        startCountdown() {
            const interval = setInterval(() => {
                if (this.currentSeconds === 0) {
                if (this.currentMinute === 0) {
                    this.stopCountdown();
                } else {
                    this.currentSeconds = 59;
                    this.currentMinute -= 1;
                }
                } else {
                this.currentSeconds -= 1;
                }
            }, 1000);
            this.countdownInterval = interval;
        },
        stopCountdown() {
            this.loadSong()
        },
        async loadSong() {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval)
                this.countdownInterval = null
            }
            if (this.songNum === 61) {
                this.player.pauseVideo()
                return
            }
            const currentNum = this.songNum
            const { songs } = this.data
            const currentSong = songs[currentNum]
            //get qrCode for current songs
            const url = await QRCode.toDataURL(`https://open.spotify.com/track/${currentSong.id}`)
            this.currentSongUrl = url
            // set currentSong
            this.currentSong = currentSong
            // play youtube video
            await this.player.loadVideoById({
               'videoId': currentSong.youtubeId,
               'startSeconds': currentSong.startTime,
            });
            // update song num
            this.songNum++
            this.$router.replace(`/player/${this.$route.params.playlistId}/${this.songNum}`)
            //start timer
            if (!this.playFullSong) {
                this.currentMinute = Math.floor(this.songSeconds / 60)
                this.currentSeconds =  this.songSeconds % 60
            } else {
                const startTime = currentSong.startTime
                const videoDuration = currentSong.videoDuration
                const songDuration = Math.round(currentSong.duration_ms / 1000)
                const value = songDuration > videoDuration ? videoDuration : songDuration
                const almostDiff = value - startTime - 1
                this.currentMinute = Math.floor(almostDiff / 60)
                this.currentSeconds =  almostDiff % 60
            }
            if (this.player.getPlayerState() === 1) {
                this.startCountdown()
            }
        }
    }
})
</script>
<style scoped lang='scss'>
    .songInfoRow {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .main {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #721D29;
    }
    .main::-webkit-scrollbar {
        visibility: hidden;
        width: 0px;
    }
    .left {
        padding: 16px;
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .right {
        width: calc(100% - 300px);
        height: 100%;
        padding: 16px;
    }
    .right::-webkit-scrollbar {
        visibility: hidden;
    }
    #youtubeVideo {
        width: 100%;
        height: 100%;
        background: gray;
    }
    #youtubeVideo::-webkit-scrollbar {
        visibility: hidden;
    }
    .songNumInfo {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .restOfInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .titleAndCard {
        display: flex;
        flex-direction: column;
    }
    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    .card1 {
        width: 100%;
        border-radius: 5px;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 128px;
        line-height: 155px;
        color: #000000;

    }
    .card2 {
        width: 100%;
        border-radius: 5px;
        background: white;
    }
    .card3 {
        width: 100%;
        border-radius: 5px;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #qrCode {
        width: 250px;
        height: 250px;
        margin: auto;
        background: gray;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    #songImage {
        height: 100px;
        width: 100px;
        background: gray;
        margin-left: 16px;
        margin-top: 16px;
        background-size: cover;
    }
    .topInfo {
        display: flex;
        flex-direction: row;
    }
    .songYear {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        color: #000000;
        flex: 1;
        align-items: center;
        padding-top: 16px;
    }
    .botInfo {
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
    .songTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
    .artistName {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
    .skipSongRow {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        color: white;
    }
    .skipText {
        cursor: pointer;
    }
    .skipText:hover {
        text-decoration: underline;
    }
</style>
