<template lang="pug">
.heroDiv1(:style='`min-height:${height};height: ${height};`')
    .cardContainer
        v-card.card(v-if='!user')
            v-col
                v-row(justify='center')
                    v-card-title.largeFont Sign In Below
                v-row(justify='center')
                    .signUpBox(@click='googleSignUp')
                        .boxImage
                            img(src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/300px-Google_%22G%22_Logo.svg.png')
                        .boxText Sign In With Google
        v-card.card(v-else)
            .myNewCol
                .centerImage
                    img.full(src='https://media.giphy.com/media/artj92V8o75VPL7AeQ/giphy.gif')
                .centerInfo
                    .centerName
                        h3.largeFont Welcome, {{ user.displayName }}!
                    .centerBlurb
                        h2.mediumFont You are now ready to enter the rocking world of playlist curation!
                    .signOut(@click='signOut') Sign Out
</template>
<script>
import { getAuth, GoogleAuthProvider,signInWithPopup } from "firebase/auth";
export default {
  name: 'Signup',
  data: () => ({
    height: '100%',
    loading: true,
    user: undefined
  }),
  methods: {
      googleSignUp() {
        const auth = getAuth()
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
        .then((result) => {
            var user = result.user;
            this.$store.commit('setCurrentUser', user)
            this.user = user
        })
      },
      signOut() {
          const auth = getAuth()
          auth.signOut()
          this.$store.commit('setCurrentUser', undefined)
          this.user = undefined
      }
  },
  async mounted() {
    const height = window.innerHeight
    const navBarHeight = document.getElementById('navBar')
    if (navBarHeight) {
      const navBarRect = navBarHeight.getBoundingClientRect()
      const height1 = navBarRect.height
      this.height = (height - height1) + 'px'
    }
    this.user = this.$store.getters.getCurrentUser
  }
};
</script>

<style scoped lang='scss'>
.myNewCol {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.cardContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.card {
    margin: 16px;
    width: calc(100% - 32px);
    max-width: 1000px;
    height:calc(100% - 32px);
}
.heroDiv1 {
    background-size: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #721d29;  
}
.centerBlurb {
    width: 100%;
    text-align: center;
}
.largeFont {
    font-size: 30px !important;
}
.signUpDiv {
    margin: auto;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.signUpText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.signUpBox {
   height: 70px;
   width: 80%;
   background-color: white;
   margin-top: 5%;
   border-radius: 5px;
   border: 1px solid black;
   display: flex;
   flex-direction: row;
   cursor: pointer;
}
.signUpBox:hover {
    background-color: rgb(2 123 196 / 4%);
}
.boxImage {
    margin: auto;
    height: 80%;
    margin-left: 5%;
}
.boxImage img {
    height: 100%;
}
.boxText {
    display: flex;
    justify-content: center;
    text-align: center;
    flex: 1;
    margin: auto;
    font-size: 20px;
    letter-spacing: 1.5px;
    font-weight: bolder;
}
.signOut {
   height: 70px;
   width: 80%;
   background-color: white;
   margin-left: auto;
   margin-right: auto;
   border-radius: 5px;
   border: 1px solid black;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   cursor: pointer;
   font-size: 30px;
   font-weight: bolder;
}
.signOut:hover {
    background-color: rgb(2 123 196 / 4%);
}
.centerName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}
.spotifyButton {
    width: 80%;
    height: 50%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
    background-color: green;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid black;
}
.spotifyButton:hover {
    background-color: rgb(2 123 196 / 4%);
}
.addPadding {
    padding: 1%;
}
.centerImage {
    height: 50%;
    width: 100%;
    padding: 16px;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.centerImage img {
    width: 326px;
}
.centerInfo {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.full {
    width: 100%;
}
</style>