<template lang="pug">
    .background1
        .queueText
            | Queue
        .upNextText(v-if='queue?.currentSong')
            | Currently Playing
        .currentSong(v-if='queue?.currentSong')
            .song
                .songDetails.maximize
                    .songName
                        | {{ queue?.currentSong?.name }}
                    .artistName(v-if='queue?.currentSong?.artists?.length > 0')
                        | {{ queue?.currentSong?.artists[0]?.name }}
        .mySmallLine1(v-if='queue && queue.currentSong')
        .upNextText(v-if='queue?.queue?.length > 0')
            | Up Next
        .songs
            .song(v-for='song, iter in queue.queue' :class='{ "first": iter === 0 }')
                .songDetails
                    .songName
                        | {{ song?.name }}
                    .artistName(v-if='song?.artists?.length > 0')
                        | {{ song?.artists[0]?.name }}
                .songOptions
                    .upvote
                        v-icon(:color='getColorUpvote(song)' large @click='upvote(song)') mdi-fire
</template>

<script>
export default {
    data: () => ({
        alreadyVoted: {},
    }),
    name: 'QueueSongs',
    props: ['queue'],
    methods: {
        getColorUpvote(song) {
            if (song.spotifyId in this.alreadyVoted) {
                if (this.alreadyVoted[song.spotifyId] === 'up') {
                    return 'red'
                } else {
                    return 'black'
                }
            } else {
                return 'black'
            }
        },
        upvote(song) {
            const canVoteUnlimited = this.$store.getters.getCurrentUser && this.$store.getters.getCurrentUser.uid === this.queue.userId
            if (!(song.spotifyId in this.alreadyVoted) || canVoteUnlimited) {
                this.alreadyVoted[song.spotifyId] = 'up'
                this.$emit('upvote', song)
            }
        },
        downvote(song) {
            const canVoteUnlimited = this.$store.getters.getCurrentUser && this.$store.getters.getCurrentUser.uid === this.queue.userId
            if (!(song.spotifyId in this.alreadyVoted) || canVoteUnlimited) {
                this.alreadyVoted[song.spotifyId] = 'down'
                this.$emit('downvote', song)
            }
        },
    }
}
</script>

<style scoped lang='scss'>
    .background1 {
        background: #f1f2f2;
        width: 100%;
        height: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
    }
    .songs {
        display: flex;
        flex-direction: column;
    }
    .song {
        color: black;
        width: auto;
        height: 100px;
        border-radius: 5px;
        background: white;
        margin: 10px;
        display: flex;
        flex-direction: row;
    }
    .queueText {
        padding: 10px;
        color: black;
        font-size: 40px;
    }
    .upNextText {
        padding: 10px;
        color: gray;
        font-size: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 0px !important;
        margin-bottom: -10px !important;
    }
    .songDetails {
        height: 100%;
        width: 60%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 12px;
    }
    .maximize {
        width: 100% !important;
    }
    .songOptions {
        height: 100%;
        width: 40%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .songName {
        font-size: 24px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .artistName {
        font-size: 18px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .upvote {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .downvote {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .number {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
    }
    .first {
        border: 3px white ridge;
    }
    .mySmallLine1 {
        margin-top: 5px;
        margin-bottom: 5px;
        width: calc(100% - 32px);
        min-height: 1px;
        background: #000;
        margin-left: auto;
        margin-right: auto;
    }
</style>