<template lang="pug">
  .myCol(:style="`min-height: ${height};`")
    .tinderButtonRow(v-if='googleTabs[selectedGoogleTab].recs.length > 0')
      .circleButton(@click='openTinderArea')
        v-icon(color='red' size='50') mdi-fire
    v-dialog(v-model='namePopup' persistent)
      .centerCard
        v-card(width='300')
          v-card-title What's your name?
          v-card-text
            v-text-field(v-model='name' placeholder='Name')
            v-text-field(v-model.number='birthYear' placeholder='Birth Year' pattern='[0-9]*')
          v-card-actions
            .centerButton
              v-btn(:disabled='name.length === 0 || !birthYear || birthYear <= 1900' @click='handleName') Enter
    ArtistView(ref='artistView' @songSelect='handleSongAddition' :fullscreen='true' :birthYear='birthYear')
    TinderView(ref='tinderView' :recs='googleTabs[selectedGoogleTab].recs' @like='handleSongAddition' @reject='rejectSong')
    v-snackbar(v-model='visible' color='transparent' timeout='1500' top)
      v-alert(:type='snackbarType') {{ snackbarText }}
    v-dialog(v-model='addingNewTab')
      v-card
        v-card-title
          | Name new tab
        v-card-text
          v-text-field(v-model='tabName' label='Tab name')
        v-card-actions
          .centerButton
            v-btn(@click='addGoogleTab') Add
    .newPlaylistInfo
      .playlistNameRow {{ playlist.name }} ({{ numUniqueSongs }})
    .googleArea
      .googleTabs
        .googleTab(@click='selectedGoogleTab = index' v-for='googleTab, index in googleTabs' :key='index' :class="{ 'selectedGoogleTab': index === selectedGoogleTab }")
          .googleTabText
            |  {{ googleTab.name }}
          .googleTabClose
            v-icon(@click='removeGoogleTab(index)') mdi-close
      .googleAdd1
        v-icon(dark large @click='inititateAddTab') mdi-plus
    .myScrollArea(v-for='googleTab, index in googleTabs' :key='`scrollArea${index}`' v-show='index === selectedGoogleTab')
      .titleRow
        | Add a song
      .tabOne
        .searchAreaRow
          SearchArea(:canSearch='true' :searchHeight='"500px"' @selectSong='handleSongAddition' @artistSelect='handleArtistClick')
        .tagsDiv
          .tagsTitle Tags:
          .tagScroll
              .tag(v-for='tag in playlist.tags' :key='tag') {{ tag }}
        .moreInfoRow
          | Want to see the playlist? Check it out
          b(style='margin-left:5px;text-decoration:underline;' @click='goToPlaylist') here

</template>

<script>
import axios from 'axios'

import ArtistView from '../components/ArtistView.vue'
import SearchArea from '../components/SearchArea.vue'
import TinderView from '../components/TinderView.vue'
import { getRecommendations } from '../services/spotifyService'

export default {
  name: 'Suggestions',
  components: {
    SearchArea,
    ArtistView,
    TinderView
  },
  data: () => ({
    height: '100%',
    googleTabs: [{
      name: 'Main',
      addedSongIds: [],
      recs: []
    }],
    name: '',
    birthYear: null,
    namePopup: false,
    selectedGoogleTab: 0,
    rejectedSongIds: [],
    playlist: {
      name: '',
      userName: '',
      songs: [],
      tab: 0
    },
    snackbarType: 'success',
    snackbarText: 'Added',
    tabs: ['Search', 'Recommendations'],
    visible: false,
    addingNewTab: false,
    tabName: '',
    lastSearch: 'null'
  }),
  async mounted() {
    await this.fetchPlaylist()
    if (!this.playlist.allowPublicSuggestions) {
      this.$router.push('/playlists')
    }
    this.height = window.innerHeight + 'px'
    window.addEventListener('resize', () => {
      this.height = window.innerHeight + 'px'
    })
    // don't remprompt if they refresh the page
    const url = window.location.href
    if (url.indexOf('name') !== -1 && url.indexOf('birthYear') !== -1) {
      // set name value and birthYear value
      const nameIndex = url.indexOf('name=') + 'name='.length
      const ampIndex = url.indexOf('&')
      const name = url.substring(nameIndex, ampIndex)
      this.name = name
      const lastSearchIndex = url.indexOf('&lastSearch=')
      const birthYear = parseInt(url.substring(ampIndex + 1 + 'birthYear='.length, lastSearchIndex))
      this.birthYear = birthYear
      const lastSearch = url.substring(lastSearchIndex + '&lastSearch='.length)
      if (lastSearch !== 'null') {
        this.googleTabs[this.selectedGoogleTab].addedSongIds.push(lastSearch)
        this.rejectedSongIds.push(lastSearch)
        this.handleRecsResponse()
      }
      // TODO re-fetch the added-song-ids and the rejectedSongIds
    } else {
      this.namePopup = true
    }
  },
  watch: {
    '$store.state.currentPlaylistDoc': function() {
      this.fetchPlaylist()
    }
  },
  computed: {
    numUniqueSongs() {
      const dict = {}
      for (const song of this.playlist.songs) {
        dict[song] = 1
      }
      return Object.keys(dict).length
    }
  },
  methods: {
    async handleRecsResponse() {
      const rejected = this.rejectedSongIds.concat(this.playlist.songs)
      const res = await getRecommendations({ tracks: this.googleTabs[this.selectedGoogleTab].addedSongIds, target: 100, popularity: 95}, rejected)

      for (let i = 0; i < this.googleTabs[this.selectedGoogleTab].recs.length; i++) {
        const song = this.googleTabs[this.selectedGoogleTab].recs[i]
        const ind = rejected.findIndex(elem => elem === song.id)
        if (ind !== -1) {
          this.googleTabs[this.selectedGoogleTab].recs.splice(i, 1)
          if (i > 0) {
            i--
          }
        }
      }
      for (const rec of res.slice(0, 10)) {
          const ind1 = this.googleTabs[this.selectedGoogleTab].recs.findIndex(elem => elem.id === rec.id)
          if (ind1 === -1) {
            this.googleTabs[this.selectedGoogleTab].recs.push(rec)
            axios.get(rec.album.images[0].url)
          }
       }
    },
    handleName() {
      this.namePopup = false
      this.updateURL()
    },
    updateURL() {
      this.$router.replace(`/suggestions/${this.playlist.docId}?name=${this.name}&birthYear=${this.birthYear}&lastSearch=${this.lastSearch}`)
    },
    openSpotifySongLink(song) {
        window.open(`https://open.spotify.com/track/${song.id}`)
    },
    openSpotifyArtistLink(artist) {
        window.open(`https://open.spotify.com/artist/${artist.id}`)
    },
    alertWrap(type, text) {
      this.snackbarText = text
      this.snackbarType = type
      this.visible = true
    },
    async rejectSong(id) {
      this.rejectedSongIds.push(id)
      this.googleTabs[this.selectedGoogleTab].recs.splice(0, 1)
      if (this.googleTabs[this.selectedGoogleTab].recs.length <= 3) {
        this.handleRecsResponse()
      }
    },
    openTinderArea() {
      this.$refs.tinderView.openModal()
    },
    inititateAddTab() {
      this.tabName = ''
      this.addingNewTab = true
    },
    removeGoogleTab(index) {
      this.googleTabs.splice(index, 1)
    },
    addGoogleTab() {
      this.googleTabs.push({
        name: this.tabName,
        addedSongIds: [],
        recs: [],
        tab: 0
      })
      this.addingNewTab = false
      this.selectedGoogleTab = this.googleTabs.length - 1
    },
    async handleArtistClick(artist) {
      let val = this.$store.state.artistsCache[artist.id]
      if (!val) {
        val = await this.$spotifyService.getArtist(artist.id)
      }
      this.$refs.artistView.displayModal(val)
    },
    goToPlaylist() {
      this.$router.push(`/playlist/${this.playlist.docId}`)
    },
    async fetchPlaylist() {
      const playlistId = this.$route.params.playlistId
      const playlist = await this.$dataGrabber.getPlaylistDoc(playlistId)
      if (!playlist) {
        this.$router.push('/playlists')
      } else {
        this.playlist = playlist
      }
    },
    async handleSongAddition(song) {
      this.lastSearch = song.id
      this.updateURL()
      const find = this.playlist.songs.findIndex(elem => elem === song.id)
        if (find !== -1) {
          const spotifyId = song.id
          this.googleTabs[this.selectedGoogleTab].addedSongIds.push(spotifyId)
          this.googleTabs[this.selectedGoogleTab].recs.splice(0, 1)
          this.handleRecsResponse()
          this.alertWrap('info','Repeat')
          return
        }
        const spotifyId = song.id
        this.googleTabs[this.selectedGoogleTab].addedSongIds.push(spotifyId)
        this.rejectedSongIds.push(spotifyId)
        this.googleTabs[this.selectedGoogleTab].recs.splice(0, 1)
        this.handleRecsResponse()
        const myObj = {
          spotifyId,
          action: 'proposed-addition',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId,
          userName: `Guest ${this.name}`,
          userId: 'guest-user-id'
        }
        this.$dataGrabber.addSongToPlaylist(myObj)
        this.alertWrap('success', 'Added')
    }
  }
};
</script>

<style scoped lang='scss'>
.myCol {
  width: 100%;
  background-color: #721d29;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 150px;
}
.myScrollArea {
  padding: 16px;
  width: 100%;
}
.myScrollArea::-webkit-scrollbar {
  visibility: hidden;
}
.playlistNameRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;
    align-items: center;
    color: white;
    white-space: nowrap;
    width: 100%;
}
.playlistOwnerRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    color: white;
    align-items: center;
    margin-left: 20px;
}
.tagsDiv {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.tagsTitle {
  font-weight: bolder;
  color: white;
  font-size: 20px;
}
.tagScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}
.tag {
  width: 46%;
  height: 20px;
  background-color: #ce4257;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 1%;
  border-bottom: 2px solid black !important;
}
.tagScroll::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.suggestASongRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 18px;
}
.searchAreaRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 500px;
}
.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}
.moreInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}
.tabsRow {
  margin-bottom: 20px;
}
.tabOne {
  display: flex;
  flex-direction: column;
}
.tabTwo {
  display: flex;
  flex-direction: column;
  padding-bottom: 300px;
}
.noRecsText {
  text-align: center;
  color: white;
  font-size: 20px;
}
.recs {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
}
.recsBox {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  background: #f1f2f2;
}
.rec {
    letter-spacing: 1.1px;
    cursor: pointer;
    font-size: 16px;
    width: 98%;
    margin: 5px;
    height: 80px;
    min-height: 80px;
    background-color: white;
    display: flex;
    flex-direction: row;
}
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 100%;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.songInfoName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
.songArtistName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: gray;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    height: 100%;
    width: 20%;
}
.googleArea {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #721d29;
  padding-left: 16px;
}
.googleAdd1 {
  width: 10%;
  height: 100%;
}
.googleTabs {
  width: 90%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  height: 40px;
  min-height: 40px;
  overflow-x: scroll;
}
.googleTabs::-webkit-scrollbar {
  visibility: hidden;
}
.googleTab {
  min-width: 150px;
  display: flex;
  width: 150px;
  height: calc(100% - 4px);
  background: gray;
  margin: 2px;
  border-radius: 5px;
  align-items: center;
}
.selectedGoogleTab {
  background-color: white !important;
}
.googleTabText {
  width: 80%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.googleTabClose {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.centerButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.tinderButtonRow {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}
.linkOut:hover {
  text-decoration: underline;
  cursor: pointer;
}
.centerCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.centerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.circleButton {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: pulse-red 4s infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(100, 0, 0, 0);
  }
}
.newPlaylistInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  background: #ff4c67;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 4px solid white;
  margin-bottom: 5px;
}
</style>
