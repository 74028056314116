<template lang='pug'>
    v-dialog(v-model='display' width='1000')
        v-card.noPlaylists(v-if='noPlaylistsText')
            v-col
                v-row
                    | {{ noPlaylistsText }}
        v-card(v-else-if='!currentPlaylist')
            v-card-title Select Playlist
            v-col.addPadding
                .playlist(v-for='playlist in playlists' :key='playlist.id')
                    .playlistRow
                        v-col.styleName(cols='6')
                            | {{ playlist.name }}
                        v-col(cols='3' justify='center')
                            | {{ playlist.tracks.total }}
                        v-col(cols='3' @click='selectPlaylist(playlist)' align='end')
                            v-btn VIEW
                    .myLine
        v-card(v-else-if='currentPlaylist')
            .backRow
                v-icon.backButton(@click='back') mdi-arrow-left
            v-card-title {{ currentPlaylistName }}
            v-card-subtitle Add Songs
            v-col.addPadding
                .buttonsRow
                    v-col(cols='6')
                        v-btn(@click='selectAll') {{ selectText }}
                    v-col(cols='6' align='end')
                        v-btn(:disabled='selected.length === 0' @click='addSongs') Add
                v-data-table(:items='currentPlaylist' :headers='headers' v-model='selected' show-select)
</template>

<script>
import { getPlaylist,getPlaylists } from '../services/spotifyService.js'

export default {
    name: 'ImportSpotifyPlaylist',
    data: () => ({
        display: false,
        token: undefined,
        playlists: [],
        currentPlaylist: undefined,
        currentPlaylistName: '',
        headers: [
            { text: "Song Name", value: "name", sortable: false },
            { text: "Artist", value: "artist", sortable: false }
        ],
        selected: [],
        selectText: 'Select All',
        noPlaylistsText: undefined
    }),
    methods: {
        async addSongs() {
            this.$emit('success', this.selected)
        },
        selectAll() {
            if (this.selected.length === 0) {
                this.selected = this.currentPlaylist
                this.selectText = 'Unselect All'
            } else {
                this.selected = []
                this.selectText = 'Select All'
            }
        },
        back() {
            this.currentPlaylist = undefined
        },
        async selectPlaylist(p) {
            this.currentPlaylistName = p.name
            const playlist = await getPlaylist(this.token, p.tracks.href)
            // fix tracks
            const mapped = playlist.map(song => {
                const track = song.track
                const artist = track.artists[0].name
                track.artist = artist
                return track
            })
            this.currentPlaylist = mapped
        },
        displayModal(token) {
            this.display = true
            this.currentPlaylist = undefined
            this.selected = []
            this.selectText = 'Select All'
            this.token = token
            this.noPlaylistsText = undefined
            getPlaylists(token).then(data => {
                if (data === undefined ) {
                    this.playlists = []
                    this.noPlaylistsText = "This is an experimental feature. If you would like to use it, please contact support."
                } else {
                    this.playlists = data
                }
            })
            
        }
    }
}
</script>
<style lang="scss" scoped>
.addPadding {
    padding-top: 20px;
    padding-bottom: 20px;
}
.styleName {
    letter-spacing: 1.5px;
}
.buttonsRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.pad {
    margin-left: 5px;
    margin-right: 5px;
}
.backRow {
    padding: 16px;
}
.backButton {
    cursor: pointer;
    height: 50px;
    width: 50px;
}
.noPlaylists {
    padding: 100px;
    font-size: 20px;
}
.playlist {
    display: flex;
    flex-direction: column;
}
.playlistRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.playlistRow:hover {
    background: aliceblue;
}
.myLine {
    width: 100%;
    height: 1px;
    background: black;
}
</style>
