<template lang="pug">
    v-dialog(fullscreen v-model='visible')
        #swipe
            .myCol
                .closeRow
                    v-icon(large @click='closeModal') mdi-close
                .artistImage
                    img(:src=' currentRec.album.images[0].url')
                .songNameRow.linkOut(@click='openSpotifySongLink(currentRec)')
                    | {{ currentRec.name }}
                .artistNameRow.linkOut(@click='openSpotifyArtistLink(currentRec.artists[0])')
                    | {{ currentRec.artists[0].name }}
                .optionsRow
                    v-btn(@click='handleReject' x-large)
                        v-icon mdi-close-circle-outline
                    v-btn(@click='handleLike' x-large)
                        v-icon(color='red') mdi-heart
</template>

<script>
import SwipeListener from 'swipe-listener';

export default ({
    name: 'TinderView',
    props: ['recs'],
    data: () => ({
        visible: false
    }),
    async mounted() {
    },
    methods: {
        handleSwipe(e) {
            const directions = e.detail.directions;
            if (directions.left) {
                this.handleReject()
            }
            if (directions.right) {
                this.handleLike()
            }
        },
        openSpotifySongLink(song) {
            window.open(`https://open.spotify.com/track/${song.id}`)
        },
        openSpotifyArtistLink(artist) {
            window.open(`https://open.spotify.com/artist/${artist.id}`)
        },
        closeModal() {
            this.visible = false
        },
        openModal() {
            this.visible = true
            setTimeout(() => {
                const swipe = document.getElementById('swipe')
                SwipeListener(swipe)
                swipe.addEventListener('swipe', this.handleSwipe)
            }, 500)
        },
        handleLike() {
            this.$emit('like', this.currentRec)
        },
        handleReject() {
            this.$emit('reject', this.currentRec.id)
        }
    },
    computed: {
        currentRec() {
            if (!this.recs || this.recs.length === 0) {
                return {
                    name: '',
                    artists: [{ name: '' }],
                    album: {
                        images: [{ url: '' }]
                    }
                }
            } else {
                return this.recs[0]
            }
        }
    }
})
</script>

<style lang='scss' scoped>
    .linkOut:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    #swipe {
        width: 100%;
        height: 100%;
        background: #f4f4f4;
        overflow: hidden;
    }
    .myCol {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background: white;
        justify-content: space-evenly;
        overflow: hidden;
        margin: 20px;
    }
    .closeRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .artistImage {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .artistImage img {
        width: 300px;
        height: 300px;
    }
    .songNameRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: bold;
        font-size: 30px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .artistNameRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 20px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .optionsRow {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
</style>
