<template lang="pug">
    .background
        .top
            .albumArt(:style='imageUrl')
        .middle
            .songName
                | {{ queue?.currentSong?.name || 'Song Name' }}
            .artistName(v-if='queue?.currentSong?.artists?.length > 0')
                | {{ queue?.currentSong?.artists[0]?.name || 'Artist Name' }}
            .hidden
                audio(id='audio' :src='null')
        .bottom
            .controls
                .previous(v-if='selectedDevice')
                    v-icon(color='black' large) mdi-skip-previous
                .play(v-if='selectedDevice')
                    v-icon(color='black' large @click='handlePlayPauseClick') {{ playing ? 'mdi-pause' : 'mdi-play' }}
                .next(v-if='selectedDevice')
                    v-icon(color='black' large @click='skipSong') mdi-skip-next
            //.contextArea
                v-textarea(v-model='context' label='Context' placeholder='Enter context here' outlined)
            .devicePicker
                .spotifySignIn(v-if='canSeeThirdTab')
                    v-icon(large color='#1DB954' @click='useSpotifyWrap') mdi-spotify
                .menu
                    v-menu(v-if='token')
                        template(v-slot:activator="{ on, attrs }")
                            v-btn(color="primary" dark v-bind="attrs" v-on="on") {{ selectedDevice?.name || 'Choose Device'}}
                        v-list
                            v-list-item(v-for="(item, index) in devices" :key="index" @click='selectedDevice = item') {{ item.name }}   
</template>

<script>
import { getAvailableDevices, getCurrentlyPlaying, getPreamble, pauseSong, playSong } from '../services/spotifyService'

export default {
    name: 'QueueControls',
    props: ['queue', 'token'],
    data: () => ({
        devices: [],
        selectedDevice: null,
        nextSongInterval: null,
        djInterval: null,
        currentlyPlayingData: null,
        playing: false,
        loading: false,
        audioSrc: null,
        djPreludes: {},
        previousResponses: [],
        context: "",
    }),
    methods: {
        async skipSong() {
            await this.$dataGrabber.queueAndReturnNextSong(this.queue.docId)
            await this.playSong()
        },
        async playSong() {
            if (this.selectedDevice && this.token) {
                playSong(this.token, this.selectedDevice.id, this.queue.currentSong.spotifyId).then(() => {
                    this.playing = true
                    this.startInterval()
                })
            }
        },
        async pauseSong() {
            if (this.selectedDevice && this.token) {
                pauseSong(this.token, this.selectedDevice.id).then(() => {
                    this.playing = false
                })
            }
        },
        async handlePlayPauseClick() {
            if (this.playing) {
                this.pauseSong()
            } else {
                this.playSong()
            }
        },
        async automaticNext() {
            if (!this.loading) {
                this.loading = true
                clearInterval(this.nextSongInterval)
                const nextSong = await this.$dataGrabber.queueAndReturnNextSong(this.queue.docId)
                if (nextSong) {
                    const { spotifyId } = nextSong
                    const preambleUrl = this.djPreludes[spotifyId]
                    if (preambleUrl && preambleUrl !== 'fetching') {
                        this.audioSrc = preambleUrl
                        await this.playAudioUntilDone();
                    }
                }
                await this.playSong()
                setTimeout(() => {
                    this.startInterval()
                    this.loading = false  
                }, 1500);
            }
        },
        async playAudioUntilDone() {
            const localThis = this
            // return a Promise that resolves when the audio has finished playing
            return new Promise((resolve) => {
                const audio = document.getElementById('audio')
                audio.onended = () => {
                    resolve()
                }
                audio.src = localThis.audioSrc
                audio.play()
            })
        },
        useSpotifyWrap() {
            this.useSpotify()
            setInterval(this.useSpotify, 1000*60*20)
        },
        useSpotify() {
        const myWindow = window.open(`https://accounts.spotify.com/authorize?client_id=${'f6ef3fc12dd645c6b22dd6962d121b36'}&response_type=token&redirect_uri=${ window.location.origin + '/home'}&scope=${encodeURI('streaming user-read-playback-state playlist-modify-private playlist-read-private')}`, 'Spotify Sign-In', "left=100,top=100,width=500,height=500")
        const doc = this
        myWindow.onmessage = function(event) {
            const { data } = event
            if (typeof data === 'string' && data.indexOf('access_token') !== -1) {
            const start = data.indexOf('access_token=') + 'access_token='.length
            const end = data.indexOf('&token_type')
            const token = data.substring(start, end)
            myWindow.close()
            doc.userSpotifyToken = token
            doc.$emit('token', token)
            }
        };
        },
        startInterval() {
            clearInterval(this.nextSongInterval)
            this.nextSongInterval = setInterval(async () => {
                getCurrentlyPlaying(this.token).then(val => {
                    this.currentlyPlayingData = val
                    const { is_playing } = val
                    // should auto-play next song
                    if (!is_playing && this.playing && this.queue.currentSong) {
                        this.automaticNext()
                    }
                })
            }, 1000);
            this.startDJInterval()
        },
        startDJInterval() {
            clearInterval(this.djInterval)
            const localThis = this
            this.djInterval = setInterval(async () => {
                const nextSongInQueue = await localThis.$dataGrabber.returnNextSongInQueue(localThis.queue.docId)
                if (nextSongInQueue) {
                    const { name, artists, spotifyId } = nextSongInQueue

                    if (localThis.djPreludes[spotifyId]) {
                        return
                    }

                    const artistName = artists[0].name

                    localThis.djPreludes[spotifyId] = 'fetching'

                    getPreamble(name, artistName).then(res => {
                        localThis.djPreludes[spotifyId] = res.audio
                    })
                }
            }, 20 * 1000)
        },
    },
    beforeDestroy() {
        clearInterval(this.nextSongInterval)
        clearInterval(this.djInterval)
    },
    computed: {
        canSeeThirdTab() {
            const user = this.$store.getters.getCurrentUser
            if (this.queue.userId && user && user.uid === this.queue.userId) {
                return true
            } else {
                return false
            }
        },
        imageUrl() {
            if (this.queue && this.queue.currentSong && this.queue.currentSong.album && this.queue.currentSong.album.images && this.queue.currentSong.album.images.length > 0) {
                return `background-image: url(${this.queue.currentSong.album.images[0].url});`
            } else {
                return 'background: white;'
            }
        }
    },
    watch: {
        'token': function(n, old) {
            if (!old && n && this.devices.length === 0) {
                getAvailableDevices(this.token).then(res => {
                    const { devices } = res
                    this.devices = devices
                })
            }
        }
    }
}
</script>

<style scoped lang='scss'>
    .background {
        background: #f1f2f2;
        width: 100%;
        height: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
    }
    .albumArt {
        height: 250px;
        width: 250px;
        background: white;
        border-radius: 5px;
        background-size: contain;
    }
    .top {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .middle {
        width: 100%;
        text-align: center;
        color: black;
    }
    .bottom {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .controls {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
        margin: auto;
        flex: 1;
    }
    .contextArea {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 60%;
        margin: auto;
        flex: 1;
    }
    .songName {
        font-size: 40px;
    }
    .artistName {
        font-size: 18px;
    }
    .devicePicker {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
    }
    .previous {
        height: 40px;
    }
    .play {
        height: 40px;
    }
    .next {
        height: 40px;
    }
    .spotifySignIn {
        display: flex;
        flex: 1;
    }
    .menu {
        display: flex;
    }
    .hidden {
        height: 1px;
        width: 1px;
        visibility: hidden;
    }
</style>