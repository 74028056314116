<template lang="pug">
    v-dialog(v-model='display' width='1400')
        v-card
            .myRow.padded
                v-col(cols='4')
                    .grow(v-if='!choice')
                        .centerCol
                            .whichChoice
                                | Which way would you like to get recommendations?
                            .choiceRow
                                v-btn(@click="choice = 'Songs'") From Songs
                            .choiceRow(@click="choice = 'Clusters'")
                                v-btn From Clusters
                    .grow(v-if='choice === "Clusters"')
                        .buttonsRow
                            v-menu(offset-y)
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(v-bind="attrs" v-on='on') {{ chosenCluster }}
                                v-list
                                    v-list-item(v-for="value,num in clusters" :key='num' @click='chosenCluster = num')
                                        v-list-item-title {{ num }}
                            v-col(align='center')
                                v-btn(@click='selectAll1') {{ selectText1 }}
                        .dataRow
                            v-data-table(:items='currentCluster' :headers='headers' v-model='selected' show-select)
                    .grow(v-if='choice === "Songs"')
                        .buttonsRow
                            v-col(align='center')
                                v-btn(@click='selectAll1') {{ selectText1 }}
                        .dataRow
                            v-data-table(:items='songs' :headers='headers' v-model='selected' show-select)
                                template(v-slot:item.name='props')
                                    .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
                v-col.slider(cols='4')
                    .sliderRow(v-for='slider in sliders' :key='slider.name')
                        .left
                            v-checkbox(v-model='slider.selected' @change='handleChange')
                        .right
                            v-slider(:max='slider.max' :min='slider.min' v-model='slider.value' :label='slider.name' @change='handleChange' :disabled='!slider.selected')
                v-col(cols='4')
                    .myCol
                        .buttonsRow
                            v-col(cols='6')
                                v-btn(@click='selectAll') {{ selectText }}
                            v-col(cols='6' align='end')
                                v-btn(:disabled='selected1.length === 0' @click='addSongs') Add
                        .dataRow
                            v-data-table(:items='results' :headers='headers' v-model='selected1' show-select)
                                template(v-slot:item.name='props')
                                    .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
                                template(v-slot:item.artist='props')
                                    .linkOut(@click='openSpotifyArtistLink(props.item.artists[0])') {{ props.item.artist }}
</template>

<script>
import { getRecommendations } from '../services/spotifyService.js'

export default {
  name: 'Recommendations',
  props: ['songs', 'clusters'],
  async mounted() {

  },
  data: () => ({
      choice: null,
      display: false,
      selected: [],
      selected1: [],
      results: [],
      target: 100,
      chosenCluster: 0,
      headers: [
        { text: "Song Name", value: "name", sortable: false },
        { text: "Artist", value: "artist", sortable: false }
      ],
      selectText: 'Select All',
      selectText1: 'Select All',
      sliders: [
          {
               name: 'acousticness',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'danceability',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'energy',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'instrumentalness',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'liveness',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'popularity',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 100,
               selected: false
          },
          {
               name: 'speechiness',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          },
          {
               name: 'valence',
               min: 0,
               max: 100,
               value: 50,
               realMin: 0,
               realMax: 1,
               selected: false
          }
      ]
  }),
  computed: {
      currentCluster() {
          const clusters = this.clusters
          if (!clusters || clusters.length === 0) {
              return []
          } else {
              return clusters[this.chosenCluster]
          }
      }
  },
  methods: {
      openSpotifyArtistLink(artist) {
          window.open(`https://open.spotify.com/artist/${artist.id}`)
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      openDialog() {
          this.display = true
          this.choice = null
          this.selectText = 'Select All'
          this.selectText1 = 'Select All'
          this.selected1 = []
          this.selected = []
          this.results = []
          this.target = 100
          this.chosenCluster = 0
      },
      async addSongs() {
            this.$emit('success', this.selected1)
        },
        selectAll() {
            if (this.selected1.length === 0) {
                this.selected1 = this.results
                this.selectText = 'Unselect All'
            } else {
                this.selected1 = []
                this.selectText = 'Select All'
            }
        },
         selectAll1() {
            if (this.selected.length === 0) {
                if (this.choice === 'Songs') {
                    this.selected = this.songs
                    this.selectText1 = 'Unselect All'
                } else {
                    this.selected = this.clusters[this.chosenCluster]
                    this.selectText1 = 'Unselect All'
                }
            } else {
                this.selected = []
                this.selectText1 = 'Select All'
            }
        },
      async handleChange() {
          if (this.selected.length > 0) {
            const myDict = {}
            for (const v of this.sliders) {
                const { name, value, max, realMax, selected } = v
                if (selected) {
                    myDict[name] = (value / max) * realMax
                }
            }
            myDict.tracks = this.selected.map(elem => elem.id)
            myDict.target = this.target
            getRecommendations(myDict, this.songs.map(elem => elem.spotifyId)).then(tracks => {
                this.results = tracks.map(elem => {
                    elem.artist = elem.artists[0].name
                    return elem
                })
            })
          }
      }
  },
  watch: {
      'selected': function(newValue) {
          if (newValue) {
              this.handleChange()
          }
      }
  }
}
</script>
<style scoped>
.myRow {
    display: flex;
    flex-direction: row;
}
.padded {
    max-height: 600px;
}
.sliderRow {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.slider {
    max-height: 100% !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
}
.left {
    width: 20%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.right {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.buttonsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 10%;
    align-items: center;
}
.dataRow {
    height: 90%;
    overflow: scroll;
    overflow-x: hidden;
}
.myCol {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.how {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
}
.targetNum {
    margin-top: 20px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
.submitRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.grow {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.centerCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    align-items: center;
}
.choiceRow {
    margin-top: 30px;
    margin-bottom: 30px;
}
.whichChoice {
    text-align: center;
}
.linkOut:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>
