<template lang="pug">
v-dialog(v-model='display')
    .myRow
        v-card.addPadding
            v-col
                v-row.padBottom(justify='center')
                    v-card-title Add Contributor
                v-row
                    v-text-field(label='Email' v-model='email')
                v-row(justify='center')
                    v-btn(:disabled='email.length === 0' @click='handleClick') Add

</template>

<script>

export default {
  name: 'Overview',
  props: ['playlist'],
  async mounted() {
  },
  data: () => ({
      display: false,
      email: ''
  }),
  methods: {
      displayModal() {
          this.display = true
          this.email = ''
      },
      async handleClick() {
          const userIndex = this.playlist.editors.findIndex(user => user.email === this.email)

          if (userIndex !== -1 || this.$store.getters.getCurrentUser.email === this.email) {
              alert('User is already a contributor to thsi project')
              return
          }

          const user = await this.$dataGrabber.getUserByEmail(this.email)

          if (!user) {
              alert('No user associated with email')
              return
          }

          await this.$dataGrabber.addContributorToPlaylist(this.playlist.docId, user)
          this.email = ''
          this.display = false
      }
  },
  computed: {
  }
}
</script>
<style scoped>
.addPadding {
    width: 600px;
    padding: 100px;
}
.padBottom {
    margin-bottom: 50px;
}
.myRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
