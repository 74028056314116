<template lang="pug">
v-dialog(v-model='display' width='500')
    v-card.adjusDimensions
        v-col(v-if='loggedIn' justify='center')
            v-row.smallBottomMargin(justify='center')
                v-card-title Create a New Playlist
            v-row.smallBottomMargin(justify='center')
                v-card-subtitle Choose a name you have not used before
            v-row(justify='center')
                v-text-field(v-model='name' label='Playlist Name' @input='handleInput')
            v-row(justify='center')
                v-btn(:disabled='!validPlaylistName' @click='createPlaylist' :loading='checking') Create Playlist
        v-col(v-else justify='center')
            .notLoggedInText
                | It seems you aren't logged in...
            .imgContainer
                img.fit(src='https://media.giphy.com/media/W6iYebZLZYEYx0oPlj/giphy.gif')
            .logInRow
                v-btn(@click='goToSignUp') Log In
</template>

<script>

export default {
  name: 'ArtistChart',
  async mounted() {
  },
  data: () => ({
    display : false,
    name: '',
    validPlaylistName: false,
    interval: null,
    checking: false
  }),
  computed: {
      loggedIn() {
          return this.$store.getters.getCurrentUser
      }
  },
  methods: {
      goToSignUp() {
          this.$router.push('/sign-in')
      },
      displayModal() {
          this.display = true
          this.name = ''
          this.validPlaylistName = false
          this.checking = false
      },
      close() {
          this.displayModal()
          this.display = false
          this.checking = false
      },
      async handleInput() {
          clearInterval(this.interval)
          this.interval = setInterval(() => {
            this.validateSong()
          }, 500);
      },
      async validateSong() {
        const val = this.name
        this.validPlaylistName = false
        this.checking = true
        this.$dataGrabber.validateNewPlaylistName(this.$store.getters.getCurrentUser.uid, val).then(bool => {
            this.validPlaylistName = bool
            this.checking = false
        })
        clearInterval(this.interval)
      },
      async createPlaylist() {
          const playlistDoc = await this.$dataGrabber.createPlaylist(this.name)
          if (playlistDoc) {
              this.$router.push(`/playlist/${playlistDoc.id}`)
          }
      }
  }
}
</script>
<style scoped>
.adjusDimensions {
    height: 200px;
    height: 400px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
}
.smallBottomMargin {
    margin-bottom: 40px !important;
}
.imgContainer {
    width: 100%;
}
.fit {
    width: 100%;
}
.logInRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.notLoggedInText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 30px;
    text-align: center;
}
</style>
