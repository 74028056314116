<template lang="pug">
    .myCol
        .settingsNameRow
            | Settings:
        .allowRandosRow
            v-checkbox(label='Allow public suggestions' v-model='playlist.allowPublicSuggestions' @change='handleChange' :disabled='!isOwner')
        .allowRandosRow
            v-checkbox(label='Make hidden' v-model='playlist.hidden' @change='handleHiddenChange' :disabled='!isOwner')
        .qrCodeRow(v-if='playlist.allowPublicSuggestions')
            img(:src='qrCode')
        .deletePlaylistLabel
            | Delete playlist:
        .deletePlaylistRow
            v-btn(icon outlined tile block @click='handleDelete' :disabled='!isOwner')
                v-icon mdi-delete
        
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'PlaylistSettings',
  data: () => ({
      qrCode: null
  }),
  async mounted() {
      QRCode.toDataURL(window.location.origin + '/suggestions/' + this.playlist.docId).then(url => {
            this.qrCode = url
    })
  },
  props: ['playlist', 'isOwner'],
  methods: {
      async handleHiddenChange() {
        await this.$dataGrabber.togglePlaylistHidden(this.playlist.docId, this.playlist.hidden)
      },
      async handleChange() {
          await this.$dataGrabber.toggleAllowPublicSuggestions(this.playlist.docId, this.playlist.allowPublicSuggestions)
      },
      async handleDelete() {
          await this.$dataGrabber.deletePlaylist(this.playlist.docId)
          this.$router.push('/playlists')
      }
  },
}
</script>
<style scoped>

.myCol {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
}
.settingsNameRow {
    font-size: 1.25rem;
}
.allowRandosRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.deletePlaylistRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100px;
}
.deletePlaylistLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    font-size: 20px; 
    margin-bottom: 20px;
}
.exportCSVDataRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    cursor: pointer;
}
</style>
