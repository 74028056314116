import Vue from 'vue'
import VueRouter from 'vue-router'

import YoutubeId from '../views/admin/YoutubeId.vue'
import CreateQueue from '../views/CreateQueue.vue'
import Home from '../views/Home.vue'
import Player from '../views/Player.vue'
import Playlist from '../views/Playlist.vue'
import Playlists from '../views/Playlists.vue'
import Queue from '../views/Queue.vue'
import Signup from '../views/Signup.vue'
import Suggestions from '../views/Suggestions.vue'
import User from '../views/User.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin/youtube-id/:id',
    component: YoutubeId,
    name: 'Admin YoutubeId'
  },
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/player/:playlistId/:songNum',
    component: Player,
    name: 'Player'
  },
  {
    path: '/player/:playlistId',
    component: Player,
    name: 'Player'
  },
  {
    path: '/queues/:queueId',
    component: Queue
  },
  {
    path: '/create-queue',
    component: CreateQueue
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/suggestions/:playlistId',
    name: 'Suggestions',
    component: Suggestions
  },
  {
    path: '/users/:userId',
    name: 'User',
    component: User
  },
  {
    path: '/home',
    name: 'Home Endpoint',
    component: Home
  },
  {
    path: '/playlists',
    name: 'Playlists',
    component: Playlists
  },
  {
    path: '/playlist/:playlistId',
    name: 'Playlist',
    component: Playlist
  },
  {
    path: '/sign-in',
    name: 'Signup',
    component: Signup
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
