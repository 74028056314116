<template lang="pug">
.playlistsMain
    v-container(:style='`min-height:${height};max-height:${height};`')
        v-col
            h1.centerText {{ displayText }}
        .searchRow(v-if='!loading')
            input.whiteInput(v-model='search' placeholder='Search' type='text')
        v-row(justify='center')
            v-card.playlist(v-for='playlist in filter(playlists)' :key='playlist.docId' @click='$router.push(`/playlist/${playlist.docId}`)')
                v-col
                    .playlistTitleDiv
                        .centerText.playlistTitle {{ playlist.name }}
                    .playlistSubtitleDiv
                        .playlistSubtitle {{ playlist.userName }}
                    .tagsDiv
                        .tagsTitle Tags:
                        .tagScroll
                            .tag(v-for='tag in playlist.tags') {{ tag }}
</template>

<script>

export default {
  name: 'User',
  data: () => ({
    height: '100%',
    playlists: [],
    displayText: 'Loading',
    search: '',
    loading: true,
    interval: null,
    user: {
        userName: ''
    }
  }),
  computed: {
      loggedIn() {
          return this.$store.getters.getCurrentUser
      }
  },
  methods: {
      async getPlaylists(userId) {
          this.playlists = await this.$dataGrabber.getPlaylistsForUser(userId)
      },
      async getUser(userId) {
          const user = await this.$dataGrabber.getUserById(userId)
          if (!user) {
              this.$router.push('/playlists')
          } else {
              this.user = user
          }
      },
      filter(arr) {
          if (this.search.length === 0) {
              return arr
          }
          const s = this.search.toLowerCase()
          return arr.filter(elem => {
              const name = elem.name.toLowerCase()
              const myTags = elem.tags.filter(tag => tag.toLowerCase().indexOf(s) !== -1)
              return name.indexOf(s) !== -1 || myTags.length > 0
            })
      },
      changeLoadingText() {
          const isLoadingText = this.displayText.indexOf('Loading') !== -1
          if (isLoadingText) {
              let numDots = 0
              for (const character of this.displayText) {
                  if (character === '.') {
                      numDots++
                  }
              }
              if (numDots === 3) {
                  this.displayText = 'Loading'
              } else {
                  this.displayText += '.'
              }
          }
      }
  },
  async mounted() {
    const height = window.innerHeight
    const navBarHeight = document.getElementById('navBar')
    this.interval = setInterval(this.changeLoadingText, 150);
    if (navBarHeight) {
      const navBarRect = navBarHeight.getBoundingClientRect()
      const height1 = navBarRect.height
      this.height = (height - height1) + 'px'
    }
    const userId = this.$route.params.userId
    await this.getUser(userId)
    await this.getPlaylists(userId)
    this.displayText = `Check out ${this.user.userName}'s Playlists below!`
    this.loading = false
    clearInterval(this.interval)
  },
  beforeDestroy() {
      clearInterval(this.interval)
  }
};
</script>

<style scoped lang='scss'>
    .playlistsMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #721d29;
    }
    .fillPage {
        height: 100% !important;
    }
    .centerText {
        text-align: center;
        font-family: BioRhyme,serif;
        color: #ff9b54;
        font-size: 40px;
        font-weight: bolder;
    }
    .playlistTitle {
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .playlistSubtitle {
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
        text-align: center;
        font-size: 20px;
        color: #ff9b54;
        opacity: 1.0 !important;
    }
    .playlist {
        width: 300px;
        height: 300px;
        border: 3px solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin: 1%;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: rgb(206 66 87);
        color: #ff9b54;
    }
    .playlist:hover {
        border: 3px solid white;
    }
    .searchRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .whiteInput {
        background-color: white;
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 5px;
        padding-left: 20px;
        letter-spacing: 1.5px;
    }
    .floatingButtonRow {
        position: sticky;
        bottom: 10px;
        left: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: 100px;
    }
    .adjustFloat {
        margin-right: 5px;
    }
    .addButton {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 1px solid;
        background-color: #ce4257;
        text-align: center;
        font-size: xxx-large;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ff9b54;
    }
    .fixAddButtonRow {
        padding-top: 10px;
        padding-right: 100px;
    }
    .playlistTitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .playlistSubtitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .tagsDiv {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .tagsTitle {
        height: 10%;
        font-weight: bolder;
        color: white;
        font-size: 20px;
    }
    .tagScroll {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        flex: 1;
        overflow: scroll;
        overflow-x: hidden;
        height: 90%;
    }
    .tag {
        width: 46%;
        height: 20px;
        background-color: #721d29;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 5px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 5px;
        padding: 1%;
        border-bottom: 2px solid black !important;
    }
    .tagScroll::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>
