<template lang='pug'>
    v-dialog(v-model='display' width='1000')
        v-card
            .myCol
                .informationSection
                    .informationLeft
                        .searchBarRow
                            v-text-field(v-model='search' hide-details outlined label='Search for key words' @keyup.enter='addToKeyWords')
                        .currentTermsRow
                            v-chip(v-for='word, index in keyWords' :key='word' close @click:close='removeWord(index)')
                                | {{ word }}
                    .informationRight
                        v-combobox(:items="allGenres" v-model='selectedGenres' outlined label="Choose Genres" hide-details multiple)
                .resultsSection
                    v-data-table.fillScrollArea(:items='filteredResults' :headers='headers')
                        template(v-slot:item.name='props')
                            .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
                        template(v-slot:item.add='props')
                            v-btn(@click='addSong(props.item)')
                                v-icon mdi-plus
</template>

<script>
import { getSongsWithKeyWords } from '../services/firebaseService.js'
import { getArtists } from '../services/spotifyService.js'

export default {
    name: 'KeyWordSongSearch',
    data: () => ({
        display: false,
        search: '',
        allGenres: [],
        selectedGenres: [],
        keyWords: [],
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Artist', value: 'artistString' },
          { text: 'Add?', value: 'add' }
      ],
      results: []
    }),
    watch: {
        'keyWords': function(value) {
            if (value.length > 0) {
                getSongsWithKeyWords(value).then(this.handleKeyWordsResponse)
            }
        }
    },
    async mounted() {

    },
    computed: {
        filteredResults() {
            if (this.selectedGenres.length === 0) {
                return this.results
            } else {
                return this.results.filter(song => {
                    if (!song.genres) {
                        return false
                    } else {
                        for (const genre of song.genres) {
                            if (this.selectedGenres.includes(genre)) {
                                return true
                            }
                        }
                    }
                })
            }
        }
    },
    methods: {
        openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
        },
        async handleKeyWordsResponse(songs) {
            const artists = songs.map(song => song.artists)
            const allArtists = []
            for (const artistArr of artists) {
                if (!artistArr) {
                    continue
                }
                for (const artist of artistArr) {
                    allArtists.push(artist)
                }
            }
            const allArtistsData = await this.getAllArtistsData(allArtists)
            const allGenresDict = {}
            const artistsToGenresDict = {}

            for (const artist of allArtistsData) {
                if (!artist.genres) {
                    continue
                }
                const genresForArtist = []
                for (const genre of artist.genres) {
                    allGenresDict[genre] = true
                    genresForArtist.push(genre)
                }
                artistsToGenresDict[artist.id] = genresForArtist
            }
            const allGenres = Object.keys(allGenresDict)

            const songsWithGenres = []
            for (const song of songs) {
                const genresForSong = []
                if (!song.artists) {
                    song.genres = []
                    continue
                }
                for (const artist of song.artists) {
                    if (!artistsToGenresDict[artist.id]) {
                        continue
                    }
                    for (const genre of artistsToGenresDict[artist.id]) {
                        genresForSong.push(genre)
                    }
                }
                song.genres = genresForSong
                songsWithGenres.push(song)
            }
            this.allGenres = allGenres
            this.results = songsWithGenres
        },
        async getAllArtistsData(artists) {
            const allArtistsData = []
            for (let i = 0; i < artists.length; i+=50) {
                const smallSlice = artists.slice(i, i+50)
                const artistsData = await getArtists(smallSlice.map(song => song.id).join(','))
                for (const a of artistsData.artists) {
                    allArtistsData.push(a)
                }
            }
            return allArtistsData
        },
        addSong(song) {
            this.$emit('success', [song])
        },
        removeWord(index) {
            this.keyWords.splice(index, 1);
        },
        addToKeyWords() {
            if (this.search.length === 0) {
                return
            }
            if (this.keyWords.length < 10) {
                this.keyWords.push(this.search);
                this.search = '';
            } else {
                const newKeyWords = this.keyWords.slice(1);
                newKeyWords.push(this.search);
                this.search = ''
            }
        },
        openDialog() {
            this.selectedGenres = []
            this.keyWords = []
            this.search = '';
            this.display = true;
        },
        closeDialog() {
            this.display = false;
        },
    }
}
</script>

<style lang='scss'>
.myCol {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
    padding: 16px;
}
.informationSection {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.informationLeft {
    display: flex;
    flex-direction: column;
    width: calc(70% - 20px);
    height: 100%;
}
.searchBarRow {

}
.currentTermsRow {

}
.informationRight {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
}
.resultsSection {
    display: flex;
    flex: 1;
    width: 100%;
}
.fillScrollArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100%;
}
.linkOut:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>