<template lang="pug">
.wrapperDiv
  .playlist
    //- Left navigation with options
    .leftNavDrawer(v-if='!isMobile')
      v-navigation-drawer(permanent expand-on-hover color='#CE4257')
        v-list.navStyles(nav)
          v-list-item(v-for='option, num in options' :key='option.name' link :class="{ 'selectedNavigationDrawer' : num === currentTab }" @click='currentTab = num')
            v-list-item-icon
              v-icon(color='white') {{ option.icon }}
            v-list-item-title(style='color:white;') {{ option.name }}
    .leftNavDrawer(v-else)
      .topNav
        .topNavIter(v-for='option, num in options' :key='option.name' link :class="{ 'selectedNavigationDrawer' : num === currentTab }" @click='currentTab = num')
          .topNavIcon
            v-icon(color='white') {{ option.icon }}
          .topNavTitle(style='color:white;') {{ option.name }}
    .bigContainer(v-if='playlist && !loading')
      .topContainer
        #displayPort
          .max(v-if='currentTab === 0')
            Overview(:playlist='playlist' :songs='songs' :currentVersion='currentVersion' :artists='artists')
          v-card.max(v-if='currentTab === 1')
            DataTable(:playlist="playlist" :canEdit='canEdit' :songs='songs' @artistClick='handleArtistClick')
          v-card.max(v-if='currentTab === 2')
            VersionComparison(:playlist="playlist" :canEdit='canEdit' :songs='playlist.songs' :currentVersion='currentVersion')
          v-card.max(v-if='currentTab === 3')
            GrooveChart.max(:songs='songs')
          v-card.max(v-if='currentTab === 4')
            ArtistChart(:artists='artists')
          v-card.max(v-if='currentTab === 5')
            GenreChart(:artists='artists')
          v-card.max(v-if='currentTab === 6')
            ClusterTable(:songs='songs' @clusters='handleClusters')
          v-card.max(v-if='currentTab === 7')
            ActionsPanel(:canEdit='canEdit' :isOwner='isOwner' @exportCSV='exportAsCSV'
            @exportToSpotify='exportToSpotify' @exportToPowerHour='exportToPowerHour' @incrementVersion='incrementVersion'
            @importSpotify='importSpotify' @openRecommendations='openRecommendations' @getSuggestions='getSuggestions'
            @keyWordSearch='openKeyWordSearch' @exportToQueue='exportToQueue')
          v-card.max(v-if='currentTab === 8')
            PlaylistSettings(:playlist='playlist' :isOwner='isOwner')
    //- Search area on right
    .rightNavBar(v-if='!isMobile')
      v-navigation-drawer(v-if='!loading' expand-on-hover right permanent v-model="searchOpen" :mini-variant.sync="mini" width='400')
        .searchWhenClosed(v-show='mini')
          v-icon(color='white' large) mdi-magnify
        .searchWhenClosed(v-show='!mini')
          SearchArea(@selectSong='handleSongAddition' :canSearch='canEdit' @artistSelect='handleArtistClick' :searchHeight='"calc(98% - 6px)"')
  v-dialog(v-model='loading' persistent width='400')
    v-container
      v-card
        v-col(justify='center')
          v-row(justify='center')
            img(src="../assets/images/walking.gif")
  v-dialog(v-model='queueLoading' persistent width='400')
    v-container
      v-card
        v-col(justify='center')
          v-row(justify='center')
            img(src="../assets/images/walking.gif")
  PowerHourExport(ref='powerHour' :songs='songs' :playlist='playlist')
  ImportSpotifyPlaylist(ref='spotPlaylist' @success='addManySongs')
  ExportToSpotify(ref='exportToSpotify' :songs='songs')
  Recommendations(ref='recs' :songs='songs' @success='addManySongs' :clusters='clusters')
  ArtistView(ref='artistView' @songSelect='handleSongAddition')
  KeyWordSongSearch(ref='keyWordSearch' @success='addManySongs')
  v-snackbar(v-model='snackbar' color='transparent' timeout='1500')
      v-alert(type='success') {{ snackbarText }}

</template>

<script>
import ActionsPanel from '../components/ActionsPanel.vue'
import ArtistChart from '../components/ArtistChart.vue'
import ArtistView from '../components/ArtistView.vue'
import ClusterTable from '../components/ClusterTable.vue'
import DataTable from '../components/DataTable.vue'
import ExportToSpotify from '../components/ExportToSpotify.vue'
import GenreChart from '../components/GenreChart.vue'
import GrooveChart from '../components/GrooveChart.vue'
import ImportSpotifyPlaylist from '../components/ImportSpotifyPlaylist.vue'
import KeyWordSongSearch from '../components/KeyWordSongSearch.vue'
import Overview from '../components/Overview.vue'
import PlaylistSettings from '../components/PlaylistSettings.vue'
import PowerHourExport from '../components/PowerHourExport.vue'
import Recommendations from '../components/Recommendations.vue'
import SearchArea from '../components/SearchArea.vue'
import VersionComparison from '../components/VersionComparison.vue'

export default {
  name: 'Playlist',
  components: {
    DataTable,
    GrooveChart,
    ArtistChart,
    SearchArea,
    GenreChart,
    Overview,
    PowerHourExport,
    ImportSpotifyPlaylist,
    ExportToSpotify,
    VersionComparison,
    Recommendations,
    PlaylistSettings,
    ArtistView,
    ActionsPanel,
    ClusterTable,
    KeyWordSongSearch,
  },
  errorCaptured(err) {
    console.log(err)
  },
  data: () => ({
    options: [
      {
        name: 'Overview',
        icon: 'mdi-view-dashboard'
      },
      {
        name: 'Songs',
        icon: 'mdi-music'
      },
      {
        name: 'Compare',
        icon: 'mdi-compare'
      },
      {
        name: 'Metrics',
        icon: 'mdi-chart-bar'
      },
      {
        name: 'Artists',
        icon: 'mdi-account-circle'
      },
      {
        name: 'Genres',
        icon: 'mdi-sort-alphabetical-ascending-variant'
      },
      {
        name: 'Clusters',
        icon: 'mdi-chart-bubble'
      },
      {
        name: 'Actions',
        icon: 'mdi-gesture-tap'
      },
      {
        name: 'Settings',
        icon: 'mdi-cog'
      }
    ],
    loading: true,
    playlist: undefined,
    currentTab: 0,
    close: true,
    currentVersion : 1.0,
    snackbar: false,
    snackbarText: '',
    searchOpen: false,
    mini: true,
    clusters: {},
    queueLoading: false,
  }),
  computed: {
    isMobile() {
      return window.innerWidth <= 400
    },
    songs() {
      if (!this.playlist) {
        return []
      } else {
        // get all songs for current version
        const mySongs = this.filterByVersion(this.playlist.songs, this.currentVersion)
        return mySongs
      }
    },
    artists() {
     if (!this.playlist) {
        return []
      } else {
        // get all songs for current version
        const mySongs = this.filterByVersion(this.playlist.songs, this.currentVersion)
        // get all artists for current version
        const artistsDict = {}
        for (const song of mySongs) {
            const arts = song.artists
            if (arts && arts.length && arts.length > 0) {
              for (const artist of arts) {
                  const { id } = artist
                  if (id in artistsDict) {
                      artistsDict[id]++
                  } else {
                      artistsDict[id] = 1
                  }
              }
            }
        }
        const artistKeys = Object.keys(artistsDict)
        const artistsArr = []
        for (const key of artistKeys) {
          const artistObj = Object.assign({}, this.$store.state.artistsCache[key])
          artistObj.artistCount = artistsDict[key]
          artistsArr.push(artistObj)
        }
        return artistsArr
      }
    },
    canEdit() {
      return this.$store.getters.getCurrentUser && ((this.$store.getters.getCurrentUser.uid === this.playlist.userId) || (this.playlist.editors.findIndex(user => user.userId === this.$store.getters.getCurrentUser.uid) !== -1))
    },
    isOwner() {
      return this.$store.getters.getCurrentUser && (this.$store.getters.getCurrentUser.uid === this.playlist.userId)
    },
    versionsArr() {
      if (!this.playlist) {
        return []
      }
      const start = 1.0
      const end = this.playlist.version
      let current = start
      const out = []
      while (current < end) {
        out.push(current)
        current += 0.1
      }
      out.push(current)
      return out
    }
  },
  methods: {
    async exportToQueue() {
      this.queueLoading = true
      const createdQueue = await this.$dataGrabber.createQueueFromPlaylist(this.playlist.docId)
      this.queueLoading = false
      this.$router.push(`/queues/${createdQueue.id}`)
    },
    openKeyWordSearch() {
      this.$refs.keyWordSearch.openDialog()
    },
    handleClusters(clusters) {
      this.clusters = clusters
    },
     exportAsCSV() {
         let outString = 'artist_name,track_name,track_id,popularity,danceability,energy,key,loudness,mode,speechiness,acousticness,instrumentalness,liveness,valence,tempo,duration_ms,time_signature'
         outString += '\n'
        this.songs.forEach(song => {
              const { name, analysis, duration_ms, spotifyId, popularity } = song
              const { danceability, energy, key, loudness, mode, speechiness, acousticness, instrumentalness, liveness, valence, tempo, time_signature } = analysis
              outString += `${song.artists[0].name.replaceAll(',', '')},${name.replaceAll(',', '')},${spotifyId},${popularity},${danceability},${energy},${key},${loudness},${mode},${speechiness},${acousticness},${instrumentalness},${liveness},${valence},${tempo},${duration_ms},${time_signature}`
              outString += '\n'
          })
          const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(outString);
            anchor.target = '_blank';
            anchor.download = 'SpotifyExport.csv';
            anchor.click();
    },
    displaySnackbar(text) {
      this.snackbar = true
      this.snackbarText = text
    },
    async handleArtistClick(artist) {
      let val = this.$store.state.artistsCache[artist.id]
      if (!val) {
        val = await this.$spotifyService.getArtist(artist.id)
      }
      this.$refs.artistView.displayModal(val)
    },
    getSuggestions() {
      this.$router.push(`/suggestions/${this.playlist.docId}`)
    },
    importSpotify() {
      const myWindow = window.open(`https://accounts.spotify.com/authorize?client_id=${'f6ef3fc12dd645c6b22dd6962d121b36'}&response_type=token&redirect_uri=${ window.location.origin + '/home'}&scope=${encodeURI('streaming user-read-playback-state playlist-modify-private playlist-read-private')}`, 'Spotify Sign-In', "left=100,top=100,width=500,height=500")
      const doc = this
      myWindow.onmessage = function(event) {
        const { data } = event
        if (typeof data === 'string' && data.indexOf('access_token') !== -1) {
          const start = data.indexOf('access_token=') + 'access_token='.length
          const end = data.indexOf('&token_type')
          const token = data.substring(start, end)
          myWindow.close()
          doc.userSpotifyToken = token
          doc.$refs.spotPlaylist.displayModal(token)
        }
      };
    },
    exportToSpotify() {
      const myWindow = window.open(`https://accounts.spotify.com/authorize?client_id=${'f6ef3fc12dd645c6b22dd6962d121b36'}&response_type=token&redirect_uri=${ window.location.origin + '/home'}&scope=${encodeURI('streaming user-read-playback-state playlist-modify-private playlist-read-private')}`, 'Spotify Sign-In', "left=100,top=100,width=500,height=500")
      const doc = this
      myWindow.onmessage = function(event) {
        const { data } = event
        if (typeof data === 'string' && data.indexOf('access_token') !== -1) {
          const start = data.indexOf('access_token=') + 'access_token='.length
          const end = data.indexOf('&token_type')
          const token = data.substring(start, end)
          myWindow.close()
          doc.userSpotifyToken = token
          doc.$refs.exportToSpotify.displayModal(token)
        }
      };
    },
    filterByVersion(songs, version) {
        const mySongs = songs.filter(song => song.version <= version).sort((a,b) => b.createdAt > a.createdAt)
        const songsDict = {}
        for (const song of mySongs) {
          const { action, spotifyId } = song
          switch (action) {
            case 'proposed-addition' : {
                // first action in cycle
                songsDict[spotifyId] = song
                break
            }
            case 'proposed-removal' : {
                // first action in cycle
                songsDict[spotifyId] = song
                break
            }
            case 'accepted-addition' : {
                songsDict[spotifyId] = song
                break
            }
            case 'rejected-addition' : {
                delete songsDict[spotifyId]
                break
            }
            case 'accepted-removal' : {
                delete songsDict[spotifyId]
                break
            }
            case 'rejected-removal' : {
                songsDict[spotifyId] = song
                break
            }
          }
        }
        const out = []
        for (const key in songsDict) {
          out.push(songsDict[key])
        }
        out.sort((a,b) => b.createdAt > a.createdAt)
        return out
    },
    openRecommendations() {
      this.$refs.recs.openDialog()
    },
    async exportToPowerHour() {
      this.$refs.powerHour.display()
    },
    async addManySongs(songs) {
      this.displaySnackbar(`Added ${songs.length} songs`)
      for (const song of songs) {
        await this.handleSongAddition(song, true)
      }
    },
    async incrementVersion() {
      await this.$dataGrabber.incrementPlaylistVersion(this.playlist.docId)
      await this.getPlaylist()
      },
      closeMenu(version) {
        this.currentVersion = version
      },
      async handleSongAddition(song, automatic=false) {
        const find = this.playlist.songs.findIndex(elem => elem.spotifyId === song.id)
        if (find !== -1) {
          if (!automatic) {
            alert('Song already exists in playlist')
          }
          return
        }
        const spotifyId = song.id
        const myObj = {
          spotifyId,
          action: 'proposed-addition',
          version: this.playlist.version,
          createdAt: new Date(),
          playlistId: this.playlist.docId
        }
        await this.$dataGrabber.addSongToPlaylist(myObj)
        if (!automatic) {
          this.displaySnackbar(`Added song`)
        }
      },
      async getPlaylist() {
          const playlist = await this.$dataGrabber.getPlaylist(this.$route.params.playlistId)
          if (playlist) {
            this.playlist = playlist
          } else {
            this.$router.push('/playlists')
          }
      }
  },
  async mounted() {
    await this.getPlaylist()
    await this.$dataGrabber.incrementPlaylistViews(this.playlist.docId)
    this.currentVersion = this.playlist.version
    this.loading = false
  },
  watch: {
    '$store.getters.getCurrentPlaylist': function(oldValue, newValue) {
      if (oldValue && newValue) {
        if (newValue) {
          this.playlist = newValue
        }
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.blackText {
  color: black;
  font-weight: bold;
}
.playlistName {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #FF9B54;
  font-size: 30px;
}
.playlistOwner {
  color: #ff9b5496;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 16px;
  margin-left: 10px;
}
.playlistVersion {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-self: flex-end;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 80px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: scroll;
}
.tab {
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: #FF9B54;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.tabs::-webkit-scrollbar {
  width: 0px;
  visibility: hidden;
}

.tab:hover {
  font-size: 22px;
  cursor: pointer;
}
.boldText {
  font-weight: bolder;
  font-size: 22px;
}
.playlistTitle {
  font-size: 30px;
}
.playlistOwner {
  font-size: 20px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  min-height: inherit !important;
}
.topContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}
.bottomContainer {
  min-height: 9%;
  max-height: 9%;
  height: 9%;
  background-color: #ce4257;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.middleContainer {
  background-color: #ce4257;
  min-height: 1%;
  height: 1%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.longLine {
  width: 99%;
  height: 1px;
  margin: auto;
  background-color: #FF9B54;
}
.topInfo {
  min-height: 5%;
  height: 5%;
  width: auto;
  margin-left: 70px;
  margin-right: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bigContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  flex: 1;
  margin: 16px;
}
.topLeft {
  width: 70%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.topRight {
    width: 30%;
    height: calc(100% - 5px);
    margin: 2.5px;
}
.max {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
#displayPort {
  margin: 5px;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
}
.versionText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 10px;
  font-size: 20px;
}
.whiteBackground {
  background-color: white;
}
.hoverCursor {
  cursor: pointer;
}
.doNotDisplay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 40px;
}
.selectedNavigationDrawer {
  background: #721D29 !important;
}
.searchWhenClosed {
  background: rgb(206 66 87);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.rightNavBar {
  display: flex;
}
// desktop
@media only screen and (min-width: 401px) {
  .wrapperDiv {
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
  }
  .playlist {
    background-color: #721d29;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .leftNavDrawer {
  display: flex;
  }
  .navStyles {
    display: flex;
    flex-direction: column;
}
}
// mobile
@media only screen and (max-width: 400px) {
  .wrapperDiv {
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
  }
  .playlist {
    background-color: #721d29;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .topNav {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    background: #CE4257;
    height: 65px;
    overflow-y: hidden;
  }
  .topNavIter {
    margin: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
