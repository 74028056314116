<template lang="pug">
    v-dialog(v-model='display' fullscreen)
        .TopSongs
            .closeRow
                .backButton
                    v-icon(v-if='step === 1' large @click='step = 0') mdi-arrow-left
                .closeButton
                    v-icon(large @click='closeDialog') mdi-close
            .area(v-if='step === 0')
                .chooseWords
                    | Click on any year to view top songs
                .playlistButtonRoww(v-for='year in Object.keys(playlists)' :key='year')
                    v-btn(@click='handleYearSelect(year)') {{ year }}
            .area(v-else style='background: #f3f3f3;')
                .topSongText
                    | Top songs of {{ selectedYear }}
                .searchResult(v-for='song in songs')
                    .type(:title='song.name + " by " + song.artists[0].name')
                        .searchIcon
                            v-icon mdi-music
                        .songInfo
                            .songInfoName(@click='openSpotifySongLink(song)')
                                | {{ song.name }}
                            .songArtistName(@click='openSpotifyArtistLink(song)')
                                |  {{ song.artists[0].name }}
                        .serachResultButton
                            v-btn(@click='selectSong(song)')
                                v-icon mdi-plus
</template>
<script>
export default {
    name: 'TopSongsByYear',
    data: () => ({
        display: false,
        step: 0,
        playlists: {
            2010: '37i9dQZF1DXc6IFF23C9jj',
            2011: '37i9dQZF1DXcagnSNtrGuJ',
            2012: '37i9dQZF1DX0yEZaMOXna3',
            2013: '37i9dQZF1DX3Sp0P28SIer',
            2014: '37i9dQZF1DX0h0QnLkMBl4',
            2015: '37i9dQZF1DX9ukdrXQLJGZ',
            2016: '37i9dQZF1DX8XZ6AUo9R4R',
            2017: '37i9dQZF1DWTE7dVUebpUW',
            2018: '37i9dQZF1DXe2bobNYDtW8',
            2019: '37i9dQZF1DWVRSukIED0e9',
            2020: '2fmTTbBkXi8pewbUvG3CeZ',
            2021: '37i9dQZF1DXbJMiQ53rTyJ'
        },
        songs: [],
        selectedYear: 0
    }),
    async mounted() {

    },
    methods: {
         openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
        },
        selectSong(song) {
          this.$emit('selectSong', song)
        },
        openSpotifyArtistLink(song) {
            window.open(`https://open.spotify.com/artist/${song.artists[0].id}`)
        },
        async handleYearSelect(year) {
            this.step++
            this.selectedYear = year
            const response = await this.$spotifyService.getPlaylistFromId(this.playlists[year])
            const { items } = response
            this.songs = items.map(elem => elem.track)
        },
        openDialog() {
            this.display = true
            this.step = 0
            this.songs = []
        },
        closeDialog() {
            this.display = false
        }
    }
}
</script>

<style scoped lang='scss'>
    .TopSongs {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background: white;
        padding: 16px;
    }
    .area {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background: white;
        padding: 16px;
    }
    .closeRow {
        display: flex;
        flex-direction: row;
    }
    .closeButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1;
    }
    .chooseWords {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        font-size: 24px;
    }
    .playlistButtonRoww {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .searchArea {
    height: calc(90% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
}
.smallMargin {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 0px !important;
    display: flex;
}
@media (min-width: 350px) and (max-width: 699px) {
    .searchIcon {
     width: 0px;
     height: 0px;
     overflow: hidden;
    }
    .searchResult {
        letter-spacing: 1.1px;
        font-size: 16px;
        width: 98%;
        margin: 5px;
        height: 80px;
        min-height: 80px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 100%;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}
}
@media (min-width: 700px) {
    .searchIcon {
     width: 20%;
     align-items: center;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    }
.searchResult {
    letter-spacing: 1.1px;
    font-size: 16px;
    width: 98%;
    margin: 5px;
    height: 60px;
    min-height: 60px;
    background-color: white;
    display: flex;
    flex-direction: row;
}
.songInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 60%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.serachResultButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    height: 100%;
    width: 20%;
}
}
.clear {
    cursor: pointer;
}
.myNewCol {
    overflow: scroll;
    background-color: #f1f2f2;
    display: flex;
    flex: 1;
    flex-direction: column;
}
.myNewCol::-webkit-scrollbar {
    width: 0px;
}
.max {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.type {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.songInfoName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
.songInfoName:hover {
    text-decoration: underline;
    cursor: pointer;
}
.songArtistName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: gray;
}
.songArtistName:hover {
    text-decoration: underline;
    cursor: pointer;
}
.newButtonArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.topSongText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}
</style>