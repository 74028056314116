<template lang="pug">
#navBar
  .navTitle
      img.navImage(:src='require("../assets/images/playlisthounddog.png")' @click='handleClick("home", 0)')
  .navTabs
    v-menu(close-on-click)
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-bind="attrs" v-on="on" icon)
            v-icon(large color='white') mdi-table-of-contents
        v-list.whiteBackground
          v-list-item.hoverCursor(v-for='label in labels' :key='label' @click='handleClick(label)')
            v-list-item-title {{ label }}
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    labels: ['Home', 'Playlists', 'Create-Queue', 'Sign-in'],
  }),
  methods: {
    handleClick(label) {
      if (`/${label.toLowerCase()}` !== this.$route.path) {
        this.$router.push('/'+label.toLowerCase())
      }
    }
  }
}
</script>
<style scoped>
  .navTitle {
      width: 40%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 20px;
      align-items: center;
  }
#navBar {
    width: 100%;
    height: 80px;
    background-color: #CE4257;
    display: flex;
    flex-direction: row;
}
.navImage {
    height: 70%;
    cursor: pointer;
}
.navTabs {
    flex: 1;
    height: 100%;
    background-color: #CE4257;
    display: flex;
    flex-direction: row;
    color: #FF9B54;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.navBarTab:hover {
    background-color: rgb(2 123 196 / 20%);
}
.navBarLine {
  height: 3px;
  width: 100%;
  background-color: #FFDD00;
}
.boldText {
  font-weight: bolder;
  font-size: 24px;
}
</style>
