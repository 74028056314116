<template lang='pug'>
    .YoutubeId
        .loading(v-if='loading')
            v-progress-circular(indeterminate)
        .notLoading(v-else)
            .songNameRow
                | {{ youtubeData.name }}
            .artistNameRow
                | {{ youtubeData.artistName }}
            .startTimeRow
                v-text-field(v-model.number='youtubeData.startTime' outlined label='Start Time (seconds)')
</template>

<script>
import { getYoutubeData, updateYoutubeStartTime } from '../../services/firebaseService.js'

export default {
    name: 'YoutubeId',
    data: () => ({
        loading: true,
        youtubeData: null,
        timeout: null
    }),
    async mounted() {
        this.loading = true
        await this.getYoutubeData()
        this.loading = false
    },
    methods: {
        async getYoutubeData() {
            const id = this.$route.params.id;
            const data = await getYoutubeData(id);
            if (!data.startTime) {
                const { songDuration, videoDuration } = data
                const diff = (videoDuration * 1000) - songDuration
                const startTime = diff > 0 ? Math.round(diff / 1000) : 0
                data.startTime = startTime
            }
            this.youtubeData = data;
        }
    },
    watch: {
        'youtubeData.startTime': function(startTime, oldValue) {
            if (oldValue && startTime !== null && startTime !== undefined) {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    updateYoutubeStartTime(this.$route.params.id, startTime)
                }, 500);
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.YoutubeId {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.notLoading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.songNameRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}
.artistNameRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-size: 24px;
}
.startTimeRow {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    margin-top: 10px;
    display: flex;
}
</style>