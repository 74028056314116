<template lang="pug">
.dataTable
    .myTopBar
        |  {{ theLastVersion > 0.99 ? `Version ${theLastVersion.toFixed(1)}` : 'Beginning'}} to Version {{ theCurrentVersion.toFixed(1) }}
    .searchBar
        v-text-field(v-model='search' label='Filter' prepend-inner-icon="mdi-magnify" clearable )
    .scrollArea
        v-data-table.fillScrollArea(:items='filteredSongs' :headers='headers')
            template(v-slot:item.artistString2='props')
                .row
                    .linkOut(@click='openSpotifyArtistLink(artist)' style='margin-right: 5px;' v-for='artist in props.item.artists' :key='`song${props.item.id}${artist.id}`') {{ artist.name }},
            template(v-slot:item.name='props')
                .linkOut(@click='openSpotifySongLink(props.item)') {{ props.item.name }}
            template(v-slot:item.createdAt='props')
                | {{ formatDate(props.item.createdAt) }}
            template(v-slot:item.userName="props")
                .circlePerson {{ initials(props.item.userName) }}
    v-dialog(v-model='makingDecision' width='400')
        v-card
            v-col
                v-row(justify='center')
                    v-card-title {{ decisionText }}
                v-row(justify='center')
                    v-col(align='center')
                        v-btn() Yes
                    v-col(align='center')
                        v-btn() No
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'VersionComparison',
  props: ['playlist', 'canEdit', 'songs', 'currentVersion'],
  async mounted() {
  },
  data: () => ({
      search: '',
      makingDecision: false,
      selectedSong: undefined,
      decisionText: "Add song to playlist?",
      type: 'Approval',
      headers: [
          { text: 'Name', value: 'name' },
          { text: 'Artist', value: 'artistString2' },
          { text: 'Added', value: 'createdAt' },
          { text: 'Action By', value: 'userName' }
      ]
  }),
  computed: {
      theLastVersion() {
          return parseFloat((this.currentVersion - 0.1).toFixed(1))
      },
      theCurrentVersion() {
          return parseFloat((this.currentVersion).toFixed(1))
      },
      filteredSongs() {
          const s = this.search ? this.search.toLowerCase() : ''

          const current = parseFloat(this.currentVersion.toFixed(1))
          const last = parseFloat((this.currentVersion - 0.1).toFixed(1))

          const currentVersion = this.filterByVersion(this.songs, current)
          const lastVersion = this.filterByVersion(this.songs, last)

        // create hashes
        const currentVersionIds = {}
        const lastVersionIds = {}
        for (const song of lastVersion) {
            const { id } = song
            lastVersionIds[id] = song
        }
        for (const song of currentVersion) {
            const { id } = song
            currentVersionIds[id] = song
        }
        const out = []

        for (const id in currentVersionIds) {
            if (!(id in lastVersionIds)) {
                //addition
                const value = Object.assign({}, currentVersionIds[id])
                value.action = 'proposed-addition'
                out.push(value)
            }
        }
        for (const id in lastVersionIds) {
            if (!(id in currentVersionIds)) {
                // removal
                const value = Object.assign({}, lastVersionIds[id])
                value.action = 'proposed-removal'
                out.push(value)
            }
        }

          if (s === '') {
            return out
          } else {
              return out.filter(song => {
                const songName = song.name.toLowerCase()
                const artistName = song.artistString.toLowerCase()
                return songName.indexOf(s) !== -1 || artistName.indexOf(s) !== -1
              })
          }  
      }
  },
  methods: {
      openSpotifyArtistLink(artist) {
          window.open(`https://open.spotify.com/artist/${artist.id}`)
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      formatDate(date) {
          return format(date, "LL/d/yy hh:mma");
      },
      initials(word) {
          const letters = word.split(" ")
          if (letters.length > 1) {
            return letters[0].substring(0,1).toUpperCase() + letters[1].substring(0,1).toUpperCase()
          } else {
            return letters[0].substring(0,1).toUpperCase()
          }
          
      },
      filterByVersion(songs, version) {
        const mySongs = songs.filter(song => song.version <= version)
        const songsDict = {}
        for (const song of mySongs) {
          const { action, spotifyId } = song
          switch (action) {
            case 'proposed-addition' : {
                // first action in cycle
                songsDict[spotifyId] = song
                break
            }
            case 'proposed-removal' : {
                // first action in cycle
                songsDict[spotifyId] = song
                break
            }
            case 'accepted-addition' : {
                songsDict[spotifyId] = song
                break
            }
            case 'rejected-addition' : {
                delete songsDict[spotifyId]
                break
            }
            case 'accepted-removal' : {
                delete songsDict[spotifyId]
                break
            }
            case 'rejected-removal' : {
                songsDict[spotifyId] = song
                break
            }
          }
        }
        const out = []
        for (const key in songsDict) {
          out.push(songsDict[key])
        }
        out.sort((a,b) => b.createdAt > a.createdAt)
        return out
    }
  }
}
</script>
<style scoped>
     .dataTable {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow: hidden;
    }
    .buttonRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px;
    }
    .nudgeLeft {
        margin-right: 20px;
    }
    .pageInformation {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
    }
    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .greenBackground {
        background-image: linear-gradient(to right, #569756, #c4f3c4);
        border: none !important;
    }
    .redBackground {
        background-image: linear-gradient(to right, red, #dec0ff);
        border: none !important;
    }
    .headers {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 5%;
    }
    .header {
        width: 25%;
        text-align: left;
        padding-left: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: 1px;
    }
    .headerTop {
        width: 25%;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .searchBar {
        height: 10%;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .scrollArea {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: scroll;
        overflow-x: hidden;
        height: 85%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .dataRow {
        display: flex;
        flex-direction: row;
        width: calc(100% - 10px);
        height: 80px;
        min-height: 80px;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
        margin: 5px;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    .circlePerson {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: red;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 2px;
        border: 2px solid white;
        cursor: pointer;
    }
    .centerHeader {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .button {
        width: 100px !important;
    }
    .myTopBar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 20px;
        letter-spacing: 1px;
    }
    .fillScrollArea {
        width: 100%;
        height: 100%;
    }
    .row {
        display: flex;
        flex-direction: row;
    }
    .linkOut:hover {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
