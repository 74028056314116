<template lang="pug">
    .CreateQueue
        .createBox
            .createText
                | Create a Session
            .buttonRow
                v-btn(:disabled='!isLoggedIn' @click='createQueue') Create
</template>

<script>

export default ({
    name: 'CreateQueue',
    async mounted() {
        
    },
    computed: {
        isLoggedIn() {
            return this.$store.state.currentUser !== undefined
        }
    },
    methods: {
        async createQueue() {
            const doc = await this.$dataGrabber.createQueue()
            this.$router.push('/queues/' + doc.id)
        }
    }
})

</script>

<style scoped lang='scss'>
.CreateQueue {
    min-height: 100%;
    height: 100%;
    width: 100%;
}
.createBox {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}
.createText {
    margin-bottom: 40px;
}
</style>