<template lang="pug">
   .ActionsPanel
    .buttonsGroup
        .labelText
            | Import:
        .centerButtons
            v-btn.paddingAround(color='green' x-large :disabled='!canEdit' icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Import from Spotify' @click='importSpotify')
                v-icon mdi-spotify
            v-btn.paddingAround(color='blue' x-large :disabled='!canEdit' icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Keyword Search' @click='keyWordSearch')
                v-icon mdi-file-word-box
    .buttonsGroup
        .labelText
            | Export:
        .centerButtons
            v-btn.paddingAround(color='green' x-large icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Export to Spotify' @click='exportToSpotify')
                v-icon mdi-spotify
            v-btn.paddingAround(color='red' x-large icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Export to PowerHour' @click='exportToPowerHour')
                v-icon mdi-glass-mug-variant
            v-btn.paddingAround(color='black' x-large icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Export as CSV' @click='exportCSV')
                v-icon mdi-download
            v-btn.paddingAround(color='yellow' x-large icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Export to shared queue' @click='exportToQueue')
                v-icon mdi-account-switch
    .buttonsGroup
        .labelText
            | Recommendations:
        .centerButtons
            v-btn.paddingAround(color='black' x-large :disabled='!canEdit' icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Get Recommendations' @click='openRecommendations')
                v-icon mdi-alert-decagram
            v-btn.paddingAround(color='green' x-large icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Get Suggestions' @click='getSuggestions')
                v-icon mdi-earth
    .buttonsGroup
        .labelText
            | Versioning:
        .centerButtons
            v-btn.paddingAround(color='blue' x-large :disabled='!isOwner' icon fab elevation='2' :style="{ backgroundColor: '#f5f5f5 !important'}" title='Increment Version' @click='incrementVersion')
                v-icon mdi-numeric-positive-1 
</template>

<script>
export default {
    name: 'ActionsPanel',
    props: ['canEdit', 'isOwner'],
    methods: {
        keyWordSearch() {
            this.$emit('keyWordSearch');
        },
        exportToSpotify() {
            this.$emit('exportToSpotify')
        },
        exportToPowerHour() {
            this.$emit('exportToPowerHour')
        },
        incrementVersion() {
            this.$emit('incrementVersion')
        },
        importSpotify() {
            this.$emit('importSpotify')
        },
        openRecommendations() {
            this.$emit('openRecommendations')
        },
        getSuggestions() {
            this.$emit('getSuggestions')
        },
        exportCSV() {
            this.$emit('exportCSV')
        },
        exportToQueue() {
            this.$emit('exportToQueue')
        },
    }
}
</script>

<style scoped lang='scss'>
.ActionsPanel {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;
}
.centerButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 16px;
}
.paddingAround {
  margin-left: 10px;
  margin-right: 10px;
}
.actionsText {
    padding: 16px;
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
}
.buttonsGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.labelText {
    font-weight: bold;
}
</style>
