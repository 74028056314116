<template lang="pug">
v-dialog(v-model='display' :fullscreen='fullscreen')
    v-card.center(v-if='display' :key='key')
        .myCol
            .closeRow
                .close(@click='closeModal') Close
            .artistInformation
                .artistName
                    .artistImage(:style='`background-image: url(${url});`')
                    .name.linkOut(@click='openSpotifyArtistLink(artist)')
                        | {{ artist.name }}
                .typeSwitch
                    .typeRow(v-if='!loading')
                        v-icon mdi-album
                        v-switch(v-model='switchValue')
                        v-icon mdi-music-note
            .normalView.scrollOverride(v-if='preSelectScreen')
                .clickATimeFram
                    | Click on a time period
                .fillAndSpace
                    .choiceRow(v-for='key, num in Object.keys(songData)' :key='key')
                        v-btn(@click='handleTabSelection(key, num)') {{ key }}
            .normalView(v-else-if='!postSelectScreen')
                .myTabs
                    v-tabs(grow center-active v-model="active_tab")
                        v-tab(@click="handleTabSelection('Top', 0)") Top ({{ songData['Top'].length }})
                        v-tab(@click="handleTabSelection('All', 1)") All ({{ songData['All'].length }})
                        v-tab(v-if='birthYear' @click="handleTabSelection('High-school', 2)") High-school ({{ songData['High-school'].length }})
                        v-tab(v-if='birthYear' @click="handleTabSelection('College', 3)") College ({{ songData['College'].length }})
                        v-tab(@click="handleTabSelection('Old', 4)") Old ({{ songData['Old'].length }})
                        v-tab(@click="handleTabSelection('Semi-old', 5)") Semi-old ({{ songData['Semi-old'].length }})
                        v-tab(@click="handleTabSelection('Semi-new', 6)") Semi-new ({{ songData['Semi-new'].length }})
                        v-tab(@click="handleTabSelection('New', 7)") New ({{ songData['New'].length }})
                .searchDiv(v-if="currentTab === 'All' && switchValue")
                    v-text-field(prepend-inner-icon="mdi-magnify" placeholder='Search songs or featured artists here' hide-deatails v-model='search' outlined clearable)
                .songs(id='songs' v-if='switchValue')
                    .loadingDiv(v-if='loading')
                        v-progress-circular(indeterminate color='red' size='150')
                    .songIter(v-for='song in filterSongs(songData[currentTab])' :key='song.id' :class="{ 'goldBackground' : song.hasStar }")
                        .songName
                            .songNameFlex.linkOut(@click='openSpotifySongLink(song)')
                                | {{ song.name }}
                        .suggestButton
                            v-btn(width='100%' @click='handleSuggestion(song)' dense)
                                v-icon mdi-plus
                .songs(v-else-if='!selectedAlbum')
                    .loadingDiv(v-if='loading')
                        v-progress-circular(indeterminate color='red' size='150')
                    .songIter(v-for='album in songData[currentTab]' :key='album.id')
                        .newSongName
                            .albumImage
                                img(:src='album.images[0].url')
                            .albumName
                                | {{ album.name }}
                        .suggestButton
                            v-btn(width='100%' dense @click='selectedAlbum = album') View
                .songs(v-else)
                    .backButton(@click='selectedAlbum = null')
                        v-icon mdi-arrow-left
                    .nameOfPlaylist
                        | {{ selectedAlbum.name }}
                    .songIter(v-for='song in selectedAlbum.tracks.items' :key='song.id')
                        .songName
                            .songNameFlex.linkOut(@click='openSpotifySongLink(song)')
                                | {{ song.name }}
                        .suggestButton
                            v-btn(width='100%' @click='handleSuggestion(song)' dense)
                                v-icon mdi-plus
            .normalView.scrollOverride(v-else)
                .viewArtistsText
                    | View similar artists?
                .artistsDiv
                    .artistsRow(style='margin-bottom:5px;')
                        .artistObj
                            .recArtistName.linkOut(@click='openSpotifyArtistLink(similarArtists[0])') {{ similarArtists[0].name }}
                            .artistImage(:style='`background-image: url(${similarArtists[0].images[0].url});`')
                            .recArtistButton
                                v-btn(@click='handleSimilarArtistClick(similarArtists[0])') View
                        .artistObj
                            .recArtistName.linkOut(@click='openSpotifyArtistLink(similarArtists[0])') {{ similarArtists[1].name }}
                            .artistImage(:style='`background-image: url(${similarArtists[1].images[0].url});`')
                            .recArtistButton
                                v-btn(@click='handleSimilarArtistClick(similarArtists[1])') View
                    .artistsRow
                        .artistObj
                            .recArtistName.linkOut(@click='openSpotifyArtistLink(similarArtists[2])') {{ similarArtists[2].name }}
                            .artistImage(:style='`background-image: url(${similarArtists[2].images[0].url});`')
                            .recArtistButton
                                v-btn(@click='handleSimilarArtistClick(similarArtists[2])') View
                        .artistObj
                            .recArtistName.linkOut(@click='openSpotifyArtistLink(similarArtists[3])') {{ similarArtists[3].name }}
                            .artistImage(:style='`background-image: url(${similarArtists[3].images[0].url});`')
                            .recArtistButton
                                v-btn(@click='handleSimilarArtistClick(similarArtists[3])') View
                .backToSongs
                    v-btn(@click='returnToSongs') Back to songs
                
</template>

<script>
import axios from 'axios'

import { getAllSongsForArtist, getArtistTopTracks, getSimilarArtists } from '../services/spotifyService'

export default {
  name: 'Overview',
  props: ['playlist', 'fullscreen',  'birthYear'],
  async mounted() {
  },
  data: () => ({
      display: false,
      artist: {},
      active_tab: 0,
      songData: {
          'Top': [],
          'All': [],
          'High-school': [],
          'College': [],
          'Old': [],
          'Semi-old': [],
          'Semi-new': [],
          'New': []
      },
      loading: false,
      switchValue: true,
      currentTab: 'Top',
      url: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.adaptrum.com%2F&psig=AOvVaw1kw2vjLIXm19h8q-4DNCRX&ust=1642454092653000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCLD40biYt_UCFQAAAAAdAAAAABAD',
      key: 0,
      postSelectScreen: false,
      preSelectScreen: true,
      similarArtists: [],
      search: '',
      allSongs: {},
      allArtists: {},
      selectedAlbum: null,
  }),
  watch: {
      'switchValue': function() {
          this.selectedAlbum = null
          this.handleTypeChoice()
      }
  },
  methods: {
      setTab(value, num) {
          this.currentTab = value
          this.active_tab = num
      },
      handleTabSelection(key, num) {
          this.setTab(key, num)
          this.preSelectScreen = false
          const elem = document.getElementById('songs')
          if (elem) {
              elem.scrollTop = 0
          }
      },
      openSpotifySongLink(song) {
          window.open(`https://open.spotify.com/track/${song.id}`)
      },
      openSpotifyArtistLink(artist) {
          window.open(`https://open.spotify.com/artist/${artist.id}`)
      },
      filterSongs(songs) {
          if (!songs || songs.length === 0) {
              return []
          } else if (!this.search || this.search.length === 0) {
              return songs
          } else {
              const l = this.search.toLowerCase()
              return songs.filter(song => {
                  const isInSongName = song.name.toLowerCase().indexOf(l) !== -1
                  if (isInSongName) {
                      return true
                  }
                  for (const artist of song.artists) {
                      const lowerName = artist.name.toLowerCase()
                      if (lowerName.indexOf(l) !== -1) {
                          return true
                      }
                  }
              })
          }
      },
      handleSimilarArtistClick(artist) {
          this.displayModal(artist)
      },
      returnToSongs() {
          this.postSelectScreen = false
      },
      closeModal() {
          this.display = false
      },
      removeSameNames(param) {
          const myHash = {}
          const out = []
          for (const song of param) {
              const hash = song.name.toLowerCase()
              if (hash in myHash) {
                  continue
              } else {
                  myHash[hash] = true
                  out.push(song)
              }
          }
          return out
      },
      compare_name( a, b )
        {
        if ( a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
        }
        return 0;
        },
      handleSuggestion(song) {
          this.$emit('songSelect', song)
          this.postSelectScreen = true
      },
      handleClickOut() {
          this.selectedAlbum = null
          this.display = false
          this.loading = false
          this.postSelectScreen = false
          this.preSelectScreen = true
          this.similarArtists = []
          this.allSongs = {}
          this.allArtists = {}
          this.currentTab = 'Top'
          this.songData = {
            'Top': [],
            'All': [],
            'High-school': [],
            'College': [],
            'Old': [],
            'Semi-old': [],
            'Semi-new': [],
            'New': []
        }
        this.search = ''
      },
      async getArtistImage() {
          await axios.get(this.artist.images[0].url)
          this.url = this.artist.images[0].url
          this.key++
      },
      async displayModal(artist) {
          this.handleClickOut()
          this.loading = true
          this.display = true
          this.artist = artist
          await this.getArtistImage()
          const res = await getAllSongsForArtist(artist.id, this.birthYear)
          const { allSongs, allAlbums } = res
          this.allSongs = allSongs
          this.allAlbums = allAlbums
          // get artist top tracks for songs
          const response = await getArtistTopTracks(artist.id)
            const topTracks = response.tracks
            for (const track of topTracks) {
                track.hasStar = true
                for (const key in this.allSongs) {
                    const arr = this.allSongs[key]
                    const index = arr.findIndex(elem => elem.id === track.id)
                    if (index !== -1) {
                        this.allSongs[key][index].hasStar = true
                    }
                }
            }
          this.allSongs['Top'] = topTracks.sort(this.compare_name)
          // get similar artists
          getSimilarArtists(artist.id).then(response => {
              const { artists } = response
              artists.sort((a,b) => b.popularity - a.popularity)
              const topFour = artists.slice(0, 4)
              this.similarArtists = topFour
          })

          this.handleTypeChoice()
          this.loading = false
      },
      handleTypeChoice() {
          //songs
          if (this.switchValue) {
            this.songData['Top'] = this.removeSameNames(this.allSongs['Top']).sort(this.compare_name)
            this.songData['Old'] = this.removeSameNames(this.allSongs['Old']).sort(this.compare_name)
            this.songData['All'] = this.removeSameNames(this.allSongs['All']).sort(this.compare_name)
            this.songData['High-school'] = this.removeSameNames(this.allSongs['High-school']).sort(this.compare_name)
            this.songData['College'] = this.removeSameNames(this.allSongs['College']).sort(this.compare_name)
            this.songData['New'] = this.removeSameNames(this.allSongs['New']).sort(this.compare_name)
            this.songData['Semi-new'] = this.removeSameNames(this.allSongs['Semi-new']).sort(this.compare_name)
            this.songData['Semi-old'] = this.removeSameNames(this.allSongs['Semi-old']).sort(this.compare_name)
          } else {
            const popularAlbums = this.removeSameNames(this.allAlbums['All']).sort((a,b) => b.popularity - a.popularity).slice(0, 10)
            this.songData['Top'] = popularAlbums.sort(this.compare_name)
            this.songData['Old'] = this.removeSameNames(this.allAlbums['Old']).sort(this.compare_name)
            this.songData['All'] = this.removeSameNames(this.allAlbums['All']).sort(this.compare_name)
            this.songData['High-school'] = this.removeSameNames(this.allAlbums['High-school']).sort(this.compare_name)
            this.songData['College'] = this.removeSameNames(this.allAlbums['College']).sort(this.compare_name)
            this.songData['New'] = this.removeSameNames(this.allAlbums['New']).sort(this.compare_name)
            this.songData['Semi-new'] = this.removeSameNames(this.allAlbums['Semi-new']).sort(this.compare_name)
            this.songData['Semi-old'] = this.removeSameNames(this.allAlbums['Semi-old']).sort(this.compare_name)
          }
      }
  }
}
</script>
<style scoped lang='scss'>
.center {
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.myCol {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;
}
.artistInformation {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.typeRow {
    display: flex;
    flex-direction: row;
}
.typeSwitch {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.artistName {
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}
.songs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 50px;
    width: 100%;
    overflow-x: hidden;
}
.loadingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.songIter {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100px;
    cursor: pointer;
    padding: 20px;
    border-bottom: 1px solid black;
}
.songIter:hover {
    background-color: rgba(210, 220, 255, 0.911);
}
.songName {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.songYear {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.suggestButton {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.artistImage {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-size: 100%;
}
.name {
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.close {
    color: gray;
    cursor: pointer;
}
.normalView {
    height: 100%;
    overflow: hidden;
}
.viewArtistsText {
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
}
.artistsDiv {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    margin-top: 20px;
}
.artistsRow {
    display: flex;
    flex-direction: row;
    height: 50%;
}
.backToSongs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.artistObj {
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.scrollOverride {
    overflow-y: scroll !important;
    padding-bottom: 20px;
}
.recArtistName {
    text-align: center;
}
.goldBackground {
    background-color: #facc6b;
    background-image: url('../assets/images/sparkles.gif');
    color: black !important;
}
.searchDiv {
    margin-top: 20px;
}
.linkOut:hover {
    text-decoration: underline;
    cursor: pointer;
}
.clickATimeFram {
    height: 100px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
}
.fillAndSpace {
    overflow: hidden;
    display: flex;
    height: calc(100% - 100px);
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.choiceRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.albumImage {
    width: 50px;
    height: 50px;
}
.albumImage img {
    width: 100%;
    height: 100%;
}
.newSongName {
    display: flex;
    flex-direction: column;
    width: 75%;
}
.albumName {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.nameOfPlaylist {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}
</style>
