<template lang='pug'>
    v-dialog(v-model='display' width='600')
        v-card.noPlaylists(v-if='noPlaylistsText')
            v-col
                v-row
                    | {{ noPlaylistsText }}
        v-card.addPadding(v-else)
            v-col
                v-row
                    v-card-title
                        | Name your playlist
                v-row
                    v-col
                        v-text-field(v-model='name' label='Playlist name')
                v-row
                    v-col(align='center')
                        v-btn(:loading='loading' @click='addPlaylist') Add
</template>

<script>
import { createPlaylist, getPlaylists, getUser } from '../services/spotifyService.js'

export default {
    name: 'ExportToSpotify',
    props: ['songs'],
    data: () => ({
        display: false,
        token: undefined,
        playlists: [],
        noPlaylistsText: undefined,
        name: '',
        loading: false,
        myId: ''
    }),
    methods: {
        displayModal(token) {
            this.display = true
            this.loading = false
            this.token = token
            getPlaylists(token).then(data => {
                if (data === undefined ) {
                    this.playlists = []
                    this.noPlaylistsText = "This is an experimental feature. If you would like to use it, please contact support."
                } else {
                    this.playlists = data.map(playlist => playlist.name)
                }
            })
            getUser(token).then(res => {
                this.myId = res.id
            })
        },
        close() {
            this.display = false
        },
        async addPlaylist() {
            const elem = this.playlists.find(elem => elem === this.name)
            if (elem) {
                alert('Name already taken. Please try a different one.')
                return
            }
            this.loading = true
            createPlaylist(this.token, this.myId, this.name, this.songs).then(() => {
                this.loading = false
                this.close()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .addPadding {
        padding-top: 100px;
        padding-bottom: 100px;
    }
</style>
