<template lang='pug'>
    .background(:style='`height:${height};width:${width};`')
        .mainContent
            QueueSongs(v-if='tab === 0' :queue='queue' @upvote='upvoteSong' @downvote='downvoteSong')
            QueueSearch(v-show='tab === 1' @songSelected='songSelected')
            QueueRecs(v-show='tab === 2' @songSelected='songSelected' :addedSongIds='addedSongIds')
            QueueControls(v-show='tab === 3' :queue='queue' @token='spotToken = $event' :token='spotToken')
            QueueQRCode(v-show='tab === 4')
        .bottomNav
            .bottomTab(@click='setTab(0)')
                v-icon(:color='tab1Color' large) {{ mdiPlaylistMusic }}
                .myText(:style='`color: ${tab1Color};`')
                    | Queue
            .bottomTab(@click='setTab(1)')
                v-icon(:color='tab2Color' large) mdi-magnify
                .myText(:style='`color: ${tab2Color};`')
                    | Search
            .bottomTab(@click='setTab(2)')
                v-icon(:color='tab3Color' large) mdi-dots-horizontal
                .myText(:style='`color: ${tab3Color};`')
                    | Recs
            .bottomTab(@click='setTab(3)' v-if='isTheOwner')
                v-icon(:color='tab4Color' large) mdi-play
                .myText(:style='`color: ${tab4Color};`')
                    | Controls
            .bottomTab(@click='setTab(4)')
                v-icon(:color='tab5Color' large) mdi-qrcode
                .myText(:style='`color: ${tab5Color};`')
                    | Share
</template>

<script>
import { mdiPlaylistMusic } from '@mdi/js';

import QueueControls from '../components/QueueControls'
import QueueQRCode from '../components/QueueQRCode'
import QueueRecs from '../components/QueueRecs'
import QueueSearch from '../components/QueueSearch'
import QueueSongs from '../components/QueueSongs'

export default ({
    name: 'Queue',
    data: () => ({
        height: '100%',
        width: '100%',
        mdiPlaylistMusic,
        queue: {
            queue: []
        },
        tab: 0,
        queueId: null,
        spotToken: null,
        addedSongIds: [],
        interval: null,
    }),
    components: { QueueSearch, QueueSongs, QueueControls, QueueRecs, QueueQRCode },
    async mounted() {
        const height = window.innerHeight - 80 + 'px'
        const width =  window.innerWidth + 'px'
        this.height = height
        this.width = width

        const queueId = this.$route.params.queueId
        this.queueId = queueId
        await this.getQueue(queueId)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        async upvoteSong(song) {
            await this.$dataGrabber.upvoteSongInQueue(this.queueId, song)
        },
        async downvoteSong(song) {
            await this.$dataGrabber.downvoteSongInQueue(this.queueId, song)
        },
        setTab(num) {
            this.tab = num
        },
        async getQueue(queueId) {
            const q = await this.$dataGrabber.getQueue(queueId)
            this.queue = q
        },
        async songSelected(song) {

            const otherIndex = this.addedSongIds.findIndex(elem => elem.spotifyId === song.id)
            if (otherIndex === -1) {
                this.addedSongIds.push(song.id)
            }

            const canVoteUnlimited = this.$store.getters.getCurrentUser && this.$store.getters.getCurrentUser.uid === this.queue.userId

            const obj = {
                spotifyId: song.id,
                createdAt: new Date(),
                userName: 'Guest',
                userId: 'GuestId',
                votes: 1,
            }
            const index = this.queue.queue.findIndex(elem => elem.spotifyId === song.id)
            if (index === -1 || canVoteUnlimited) {
                 await this.$dataGrabber.addSongToQueue(this.queueId, obj)
            }
        }
    },
    computed: {
        tab1Color() {
            return this.tab === 0 ? 'white' : 'grey'
        },
        tab2Color() {
            return this.tab === 1 ? 'white' : 'grey'
        },
        tab3Color() {
            return this.tab === 2 ? 'white' : 'grey'
        },
        tab4Color() {
            return this.tab === 3 ? 'white' : 'grey'
        },
        tab5Color() {
            return this.tab === 4 ? 'white' : 'grey'
        },
        isTheOwner() {
            const user = this.$store.getters.getCurrentUser
            if (!user || !this.queue || !this.queue.userId) {
                return false
            } else {
                if (user.uid === this.queue.userId) {
                    return true
                }
                return false
            }
        }
    }
})
</script>

<style scoped lang='scss'>
.background {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.bottomNav {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: black;
    height: 80px;
}
.mainContent {
    display: flex;
    height: calc(100% - 80px);
    width: 100%;
    background: white;
    flex-direction: column;
    overflow: hidden;
}
.bottomTab {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.myText {
    font-size: 12px;
}
</style>