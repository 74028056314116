import { render, staticRenderFns } from "./SearchArea.vue?vue&type=template&id=c84dca32&scoped=true&lang=pug"
import script from "./SearchArea.vue?vue&type=script&lang=js"
export * from "./SearchArea.vue?vue&type=script&lang=js"
import style0 from "./SearchArea.vue?vue&type=style&index=0&id=c84dca32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c84dca32",
  null
  
)

export default component.exports