<template lang="pug">
#myChartWrapper
    BarChart(:chartData='myData' :style='`min-height:${height};height:${height};`' :options='options')
</template>

<script>
import BarChart from './BarChart.vue'

export default {
  name: 'ArtistChart',
  components: {
      BarChart
  },
  data: () => (
    {
        height: '100%',
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                enabled: true
            },
            scales: {
        yAxes: [{
            display: true,
            ticks: {
                suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                // OR //
                beginAtZero: true   // minimum value will be 0.
            }
        }]
    }
        },
        myData: {}
    }
  ),
  async mounted() {
      const viewport = document.getElementById('displayPort')
      if (viewport) {
          const rect = viewport.getBoundingClientRect()
          this.height = rect.height + 'px'
      }
      this.fetchData()
  },
  props: ['artists'],
  methods: {
      fetchData() {
            const out = {
            datasets: [{ data: [] }],
            labels: []
          }
          const artistArr = this.artists.map(artist => artist.artistCount)
          const colors = this.artists.map(artist => artist.name)
          out.datasets[0].data = artistArr
          //out.datasets[0].radius = 300
          out.datasets[0].backgroundColor = artistArr.map(() => '#' + Math.floor(Math.random()*16777215).toString(16))
          out.labels = colors
          this.myData = out
      }
  },
  watch: {
      'artists': function() {
          this.fetchData()
      }
  }
}
</script>
<style scoped>
#myChartWrapper {
    min-height: 100%;
    max-height: 100%;
}
.maxHeight {
    height: 100%;
    min-height: 100%;
}
</style>
