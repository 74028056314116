<template lang="pug">
#grooveChart
    .tabsRow(:key='tabsKey')
        v-combobox(multiple v-model='selected' :items='Object.keys(allowed)' :allow-overflow='false' hide-details)
    #myChartWrapper(:key='"chart"+chartKey')
        LineChart(:chartData='myData' :options='options' ref='lineChart' :style='`min-height:${height};height:${height};`')
</template>

<script>
import LineChart from './LineChart'

export default {
  name: 'GrooveChart',
  components: {
      LineChart
  },
  data: () => ({
      selected: ['energy'],
      allowed: {
        "danceability": 'blue',
        "energy": 'red',
        "speechiness": 'yellow',
        "acousticness": 'green',
        "instrumentalness": 'orange',
        "liveness": 'black',
        "valence": 'brown',
        "year": 'purple'
    },
    height: '100%',
    options: {
        responsive: true,
        maintainAspectRatio: false
    },
    tabsKey: 0,
    chartKey: 0,
    myData: {}
  }),
  async mounted() {
      const viewport = document.getElementById('displayPort')
      if (viewport) {
          const rect = viewport.getBoundingClientRect()
          this.height = rect.height - 50 + 'px'
      }
      this.fetchData()
  },
  props: ['songs'],
  watch: {
      'songs': function() {
          this.fetchData()
      },
      'selected': function() {
          this.fetchData()
      }
  },
  methods: {
      fetchData() {
          this.myData = {
            labels : this.songs.map((elem) => elem.name),
            datasets: this.selected.map(elem => (
                {
                    label: elem,
                    data: elem !== 'year' ? this.songs.map(e => Math.round(e.analysis[elem] * 100) ) : this.songs.map(e => Math.round(e.releaseYear) ),
                    backgroundColor: this.allowed[elem],
                    fill: this.allowed[elem]
                }
            ))
        }
      }
  },
}
</script>
<style scoped>
#grooveChart {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.tabsRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    height: 40px;
    width: 30%;
    margin-left: 55px;
}
.boldText {
    font-weight: bolder;
}
.tab {
    flex: 1;
    cursor: pointer;
    border: 1px solid;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 5px;
    background-color: inherit;
    border-radius: 5px;
    letter-spacing: 1.25px;
    padding: 2px;
}
.lightBack {
    background-color: rgb(2 123 196 / 20%) !important;
}
#myChartWrapper {
    min-height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    height: calc(100% - 50px);
}
</style>
